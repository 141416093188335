import axios from "axios";

const state = {
  tracking: [],
  loading: false,
};

const mutations = {
  setTracking(state, tracking) {
    state.tracking = tracking;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
};

const actions = {
  async fetchTrackings({ commit }, { jobId }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/tracking/${jobId}`
      );

      commit("setTracking", response.data.data);
    } catch (error) {
      console.log("Fetch Tracking Error:", error);
    } finally {
      commit("setLoading", false);
    }
  },
};

const getters = {
  getTrackings: (state) => state.tracking,
  isLoading: (state) => state.loading,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
