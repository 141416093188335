import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import Dashboard from "@/views/DashboardPage/Index.vue";
// import Report from "@/views/Report/Index.vue";
import Signup from "@/views/Signup.vue";
import Signin from "@/views/Signin.vue";
import SendTypeSelectionPage from "@/views/SendTypeSelectionPage.vue";
import CreatePage from "@/views/CreatePage/Index.vue";
import DetailsPage from "@/views/JobDetails/Index.vue";
import ReportsPage from "@/views/JobDetails/ReportsPage.vue";
import ChatsPage from "@/views/ChatsPage/Index.vue";
import ChatDetailsPage from "@/views/ChatsPage/ChatDetailsPage.vue";
import MediaPage from "@/views/MediaPage/Index.vue";
import DNCManagerPage from "@/views/DNCManager/Index.vue";
import PhoneListsPage from "@/views/PhoneLists/Index.vue";
import SMSAgentsPage from "@/views/SMSAgents/Index.vue";
import IdentitiesPage from "@/views/Identities/Index.vue";
import AgenciesPage from "@/views/AgenciesPage/Index.vue";
import IdentityDetailsPage from "@/views/Identities/IdentityDetails.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import P2PPhoneListPage from "@/views/P2P/index.vue";
import P2PChatDetailPage from "@/views/P2P/P2PSendDetailsPage.vue";
// import DonationsPage from "@/views/Donations/index.vue";
import GuidPage from "@/views/Guid/index.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/select-send-type",
    name: "SelectSendType",
    component: SendTypeSelectionPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/:sendType/create",
    name: "Create",
    component: CreatePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/job/edit/:id",
    name: "Job Edit",
    component: CreatePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/:sendType/:id",
    name: "Details",
    component: DetailsPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/:sendType/:id/edit",
    name: "Edit",
    component: CreatePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/:sendType/:id/reports",
    name: "Reports",
    component: ReportsPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/media",
    name: "Media",
    component: MediaPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/dnc",
    name: "DNCManager",
    component: DNCManagerPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/phonelist",
    name: "PhoneList",
    component: PhoneListsPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/agent",
    name: "P2PSMSAgents",
    component: SMSAgentsPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/identity",
    name: "Identities",
    component: IdentitiesPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/identity/:id",
    name: "IdentityDetails",
    component: IdentityDetailsPage,
    meta: { requiresAuth: true },
  },
  // {
  //   path: "/report",
  //   name: "JobReports",
  //   component: Report,
  //   meta: { requiresAuth: true },
  // },
  {
    path: "/chats",
    name: "Chats",
    component: ChatsPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/chats/:id",
    name: "ChatDetails",
    component: ChatDetailsPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/agencies",
    name: "Agencies",
    component: AgenciesPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/p2p-phonelist",
    name: "P2PPhoneList",
    component: P2PPhoneListPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/p2pchats/:id",
    name: "P2PDetails",
    component: P2PChatDetailPage,
    meta: { requiresAuth: true },
  },
  // {
  //   path: "/donations",
  //   name: "Donations",
  //   component: DonationsPage,
  // },
  {
    path: "/:id",
    name: "Campaign",
    component: GuidPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

// Navigation guard to check if a route requires authentication
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.user.isAuthenticated;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !isAuthenticated) {
    // Redirect to sign-in page if route requires authentication and user is not authenticated
    next("/signin");
  } else if (to.name === "Signin" && isAuthenticated) {
    // Redirect to home page if trying to access sign-in page while already authenticated
    next("/");
  } else {
    // Proceed to the requested route
    next();
  }
});

export default router;
