export const ScheduleTimezone = [
  // { prefix_number: 5255, diff_utc: -6 },
  { prefix_number: 525, diff_utc: -6 },
  { prefix_number: 201, diff_utc: -5 },
  { prefix_number: 202, diff_utc: -5 },
  { prefix_number: 203, diff_utc: -5 },
  { prefix_number: 204, diff_utc: -6 },
  { prefix_number: 205, diff_utc: -6 },
  { prefix_number: 206, diff_utc: -8 },
  { prefix_number: 207, diff_utc: -5 },
  { prefix_number: 208, diff_utc: -7 },
  { prefix_number: 209, diff_utc: -8 },
  { prefix_number: 210, diff_utc: -6 },
  { prefix_number: 212, diff_utc: -5 },
  { prefix_number: 213, diff_utc: -8 },
  { prefix_number: 214, diff_utc: -6 },
  { prefix_number: 215, diff_utc: -5 },
  { prefix_number: 216, diff_utc: -5 },
  { prefix_number: 217, diff_utc: -6 },
  { prefix_number: 218, diff_utc: -6 },
  { prefix_number: 219, diff_utc: -6 },
  { prefix_number: 220, diff_utc: -5 },
  { prefix_number: 223, diff_utc: -5 },
  { prefix_number: 224, diff_utc: -6 },
  { prefix_number: 225, diff_utc: -6 },
  { prefix_number: 226, diff_utc: -5 },
  { prefix_number: 228, diff_utc: -6 },
  { prefix_number: 229, diff_utc: -5 },
  { prefix_number: 231, diff_utc: -5 },
  { prefix_number: 234, diff_utc: -5 },
  { prefix_number: 236, diff_utc: -8 },
  { prefix_number: 239, diff_utc: -5 },
  { prefix_number: 240, diff_utc: -5 },
  { prefix_number: 242, diff_utc: -5 },
  { prefix_number: 246, diff_utc: -4 },
  { prefix_number: 248, diff_utc: -5 },
  { prefix_number: 250, diff_utc: -8 },
  { prefix_number: 251, diff_utc: -6 },
  { prefix_number: 252, diff_utc: -5 },
  { prefix_number: 253, diff_utc: -8 },
  { prefix_number: 254, diff_utc: -6 },
  { prefix_number: 256, diff_utc: -6 },
  { prefix_number: 256, diff_utc: -5 },
  { prefix_number: 262, diff_utc: -6 },
  { prefix_number: 264, diff_utc: -4 },
  { prefix_number: 267, diff_utc: -5 },
  { prefix_number: 268, diff_utc: -4 },
  { prefix_number: 269, diff_utc: -5 },
  { prefix_number: 270, diff_utc: -6 },
  { prefix_number: 272, diff_utc: -5 },
  { prefix_number: 276, diff_utc: -5 },
  { prefix_number: 278, diff_utc: -5 },
  { prefix_number: 281, diff_utc: -6 },
  { prefix_number: 283, diff_utc: -5 },
  { prefix_number: 284, diff_utc: -4 },
  { prefix_number: 289, diff_utc: -5 },
  { prefix_number: 301, diff_utc: -5 },
  { prefix_number: 302, diff_utc: -5 },
  { prefix_number: 303, diff_utc: -7 },
  { prefix_number: 304, diff_utc: -5 },
  { prefix_number: 305, diff_utc: -5 },
  { prefix_number: 306, diff_utc: -6 },
  { prefix_number: 307, diff_utc: -7 },
  { prefix_number: 308, diff_utc: -6 },
  { prefix_number: 309, diff_utc: -6 },
  { prefix_number: 310, diff_utc: -8 },
  { prefix_number: 312, diff_utc: -6 },
  { prefix_number: 313, diff_utc: -5 },
  { prefix_number: 314, diff_utc: -6 },
  { prefix_number: 315, diff_utc: -5 },
  { prefix_number: 315, diff_utc: -5 },
  { prefix_number: 316, diff_utc: -6 },
  { prefix_number: 317, diff_utc: -5 },
  { prefix_number: 318, diff_utc: -6 },
  { prefix_number: 319, diff_utc: -6 },
  { prefix_number: 320, diff_utc: -6 },
  { prefix_number: 321, diff_utc: -5 },
  { prefix_number: 323, diff_utc: -8 },
  { prefix_number: 325, diff_utc: -6 },
  { prefix_number: 330, diff_utc: -5 },
  { prefix_number: 331, diff_utc: -6 },
  { prefix_number: 332, diff_utc: -5 },
  { prefix_number: 334, diff_utc: -6 },
  { prefix_number: 336, diff_utc: -5 },
  { prefix_number: 337, diff_utc: -6 },
  { prefix_number: 339, diff_utc: -5 },
  { prefix_number: 340, diff_utc: -4 },
  { prefix_number: 341, diff_utc: -8 },
  { prefix_number: 343, diff_utc: -5 },
  { prefix_number: 345, diff_utc: -5 },
  { prefix_number: 346, diff_utc: -6 },
  { prefix_number: 347, diff_utc: -5 },
  { prefix_number: 351, diff_utc: -5 },
  { prefix_number: 352, diff_utc: -5 },
  { prefix_number: 360, diff_utc: -8 },
  { prefix_number: 361, diff_utc: -6 },
  { prefix_number: 365, diff_utc: -5 },
  { prefix_number: 369, diff_utc: -8 },
  { prefix_number: 380, diff_utc: -5 },
  { prefix_number: 385, diff_utc: -7 },
  { prefix_number: 386, diff_utc: -5 },
  { prefix_number: 401, diff_utc: -5 },
  { prefix_number: 402, diff_utc: -6 },
  { prefix_number: 403, diff_utc: -7 },
  { prefix_number: 404, diff_utc: -5 },
  { prefix_number: 405, diff_utc: -6 },
  { prefix_number: 406, diff_utc: -7 },
  { prefix_number: 407, diff_utc: -5 },
  { prefix_number: 408, diff_utc: -8 },
  { prefix_number: 409, diff_utc: -6 },
  { prefix_number: 410, diff_utc: -5 },
  { prefix_number: 412, diff_utc: -5 },
  { prefix_number: 413, diff_utc: -5 },
  { prefix_number: 414, diff_utc: -6 },
  { prefix_number: 415, diff_utc: -8 },
  { prefix_number: 416, diff_utc: -5 },
  { prefix_number: 417, diff_utc: -6 },
  { prefix_number: 418, diff_utc: -5 },
  { prefix_number: 419, diff_utc: -5 },
  { prefix_number: 423, diff_utc: -5 },
  { prefix_number: 424, diff_utc: -8 },
  { prefix_number: 425, diff_utc: -8 },
  { prefix_number: 430, diff_utc: -6 },
  { prefix_number: 431, diff_utc: -6 },
  { prefix_number: 432, diff_utc: -7 },
  { prefix_number: 434, diff_utc: -5 },
  { prefix_number: 435, diff_utc: -7 },
  { prefix_number: 437, diff_utc: -5 },
  { prefix_number: 438, diff_utc: -5 },
  { prefix_number: 440, diff_utc: -5 },
  { prefix_number: 441, diff_utc: -4 },
  { prefix_number: 442, diff_utc: -8 },
  { prefix_number: 443, diff_utc: -5 },
  { prefix_number: 450, diff_utc: -5 },
  { prefix_number: 458, diff_utc: -8 },
  { prefix_number: 464, diff_utc: -6 },
  { prefix_number: 469, diff_utc: -6 },
  { prefix_number: 470, diff_utc: -5 },
  { prefix_number: 473, diff_utc: -4 },
  { prefix_number: 475, diff_utc: -5 },
  { prefix_number: 478, diff_utc: -5 },
  { prefix_number: 479, diff_utc: -6 },
  { prefix_number: 480, diff_utc: -7 },
  { prefix_number: 481, diff_utc: -5 },
  { prefix_number: 484, diff_utc: -5 },
  { prefix_number: 501, diff_utc: -6 },
  { prefix_number: 502, diff_utc: -5 },
  { prefix_number: 503, diff_utc: -8 },
  { prefix_number: 504, diff_utc: -6 },
  { prefix_number: 505, diff_utc: -7 },
  { prefix_number: 506, diff_utc: -4 },
  { prefix_number: 507, diff_utc: -6 },
  { prefix_number: 508, diff_utc: -5 },
  { prefix_number: 509, diff_utc: -8 },
  { prefix_number: 510, diff_utc: -8 },
  { prefix_number: 512, diff_utc: -6 },
  { prefix_number: 513, diff_utc: -5 },
  { prefix_number: 514, diff_utc: -5 },
  { prefix_number: 515, diff_utc: -6 },
  { prefix_number: 516, diff_utc: -5 },
  { prefix_number: 517, diff_utc: -5 },
  { prefix_number: 518, diff_utc: -5 },
  { prefix_number: 519, diff_utc: -5 },
  { prefix_number: 520, diff_utc: -7 },
  { prefix_number: 530, diff_utc: -8 },
  { prefix_number: 539, diff_utc: -6 },
  { prefix_number: 540, diff_utc: -5 },
  { prefix_number: 541, diff_utc: -8 },
  { prefix_number: 548, diff_utc: -5 },
  { prefix_number: 551, diff_utc: -5 },
  { prefix_number: 557, diff_utc: -6 },
  { prefix_number: 559, diff_utc: -8 },
  { prefix_number: 561, diff_utc: -5 },
  { prefix_number: 562, diff_utc: -8 },
  { prefix_number: 563, diff_utc: -6 },
  { prefix_number: 564, diff_utc: -8 },
  { prefix_number: 567, diff_utc: -5 },
  { prefix_number: 570, diff_utc: -5 },
  { prefix_number: 571, diff_utc: -5 },
  { prefix_number: 573, diff_utc: -6 },
  { prefix_number: 574, diff_utc: -5 },
  { prefix_number: 575, diff_utc: -7 },
  { prefix_number: 579, diff_utc: -5 },
  { prefix_number: 580, diff_utc: -6 },
  { prefix_number: 581, diff_utc: -5 },
  { prefix_number: 585, diff_utc: -5 },
  { prefix_number: 586, diff_utc: -5 },
  { prefix_number: 587, diff_utc: -7 },
  { prefix_number: 601, diff_utc: -6 },
  { prefix_number: 602, diff_utc: -7 },
  { prefix_number: 603, diff_utc: -5 },
  { prefix_number: 604, diff_utc: -8 },
  { prefix_number: 605, diff_utc: -6 },
  { prefix_number: 606, diff_utc: -5 },
  { prefix_number: 607, diff_utc: -5 },
  { prefix_number: 608, diff_utc: -6 },
  { prefix_number: 609, diff_utc: -5 },
  { prefix_number: 610, diff_utc: -5 },
  { prefix_number: 612, diff_utc: -6 },
  { prefix_number: 613, diff_utc: -5 },
  { prefix_number: 614, diff_utc: -5 },
  { prefix_number: 615, diff_utc: -6 },
  { prefix_number: 616, diff_utc: -5 },
  { prefix_number: 617, diff_utc: -5 },
  { prefix_number: 618, diff_utc: -6 },
  { prefix_number: 619, diff_utc: -8 },
  { prefix_number: 620, diff_utc: -6 },
  { prefix_number: 623, diff_utc: -7 },
  { prefix_number: 626, diff_utc: -8 },
  { prefix_number: 627, diff_utc: -8 },
  { prefix_number: 628, diff_utc: -8 },
  { prefix_number: 629, diff_utc: -6 },
  { prefix_number: 630, diff_utc: -6 },
  { prefix_number: 631, diff_utc: -5 },
  { prefix_number: 636, diff_utc: -6 },
  { prefix_number: 639, diff_utc: -6 },
  { prefix_number: 641, diff_utc: -6 },
  { prefix_number: 646, diff_utc: -5 },
  { prefix_number: 647, diff_utc: -5 },
  { prefix_number: 649, diff_utc: -5 },
  { prefix_number: 650, diff_utc: -8 },
  { prefix_number: 651, diff_utc: -6 },
  { prefix_number: 657, diff_utc: -8 },
  { prefix_number: 660, diff_utc: -6 },
  { prefix_number: 661, diff_utc: -8 },
  { prefix_number: 662, diff_utc: -6 },
  { prefix_number: 664, diff_utc: -4 },
  { prefix_number: 667, diff_utc: -5 },
  { prefix_number: 669, diff_utc: -8 },
  { prefix_number: 670, diff_utc: +10 },
  { prefix_number: 671, diff_utc: +10 },
  { prefix_number: 678, diff_utc: -5 },
  { prefix_number: 679, diff_utc: -5 },
  { prefix_number: 681, diff_utc: -5 },
  { prefix_number: 682, diff_utc: -6 },
  { prefix_number: 684, diff_utc: -11 },
  { prefix_number: 689, diff_utc: -5 },
  { prefix_number: 701, diff_utc: -6 },
  { prefix_number: 702, diff_utc: -8 },
  { prefix_number: 703, diff_utc: -5 },
  { prefix_number: 704, diff_utc: -5 },
  { prefix_number: 705, diff_utc: -5 },
  { prefix_number: 706, diff_utc: -5 },
  { prefix_number: 707, diff_utc: -8 },
  { prefix_number: 708, diff_utc: -6 },
  { prefix_number: 709, diff_utc: -4 },
  { prefix_number: 712, diff_utc: -6 },
  { prefix_number: 713, diff_utc: -6 },
  { prefix_number: 714, diff_utc: -8 },
  { prefix_number: 715, diff_utc: -6 },
  { prefix_number: 716, diff_utc: -5 },
  { prefix_number: 717, diff_utc: -5 },
  { prefix_number: 718, diff_utc: -5 },
  { prefix_number: 719, diff_utc: -7 },
  { prefix_number: 720, diff_utc: -7 },
  { prefix_number: 721, diff_utc: -4 },
  { prefix_number: 724, diff_utc: -5 },
  { prefix_number: 725, diff_utc: -8 },
  { prefix_number: 727, diff_utc: -5 },
  { prefix_number: 731, diff_utc: -6 },
  { prefix_number: 732, diff_utc: -5 },
  { prefix_number: 734, diff_utc: -5 },
  { prefix_number: 737, diff_utc: -6 },
  { prefix_number: 740, diff_utc: -5 },
  { prefix_number: 743, diff_utc: -5 },
  { prefix_number: 747, diff_utc: -8 },
  { prefix_number: 754, diff_utc: -5 },
  { prefix_number: 757, diff_utc: -5 },
  { prefix_number: 758, diff_utc: -4 },
  { prefix_number: 760, diff_utc: -8 },
  { prefix_number: 762, diff_utc: -5 },
  { prefix_number: 763, diff_utc: -6 },
  { prefix_number: 764, diff_utc: -8 },
  { prefix_number: 765, diff_utc: -5 },
  { prefix_number: 767, diff_utc: -4 },
  { prefix_number: 769, diff_utc: -6 },
  { prefix_number: 770, diff_utc: -5 },
  { prefix_number: 772, diff_utc: -5 },
  { prefix_number: 773, diff_utc: -6 },
  { prefix_number: 774, diff_utc: -5 },
  { prefix_number: 775, diff_utc: -8 },
  { prefix_number: 778, diff_utc: -8 },
  { prefix_number: 779, diff_utc: -6 },
  { prefix_number: 779, diff_utc: -7 },
  { prefix_number: 780, diff_utc: -7 },
  { prefix_number: 781, diff_utc: -5 },
  { prefix_number: 782, diff_utc: -4 },
  { prefix_number: 784, diff_utc: -4 },
  { prefix_number: 785, diff_utc: -6 },
  { prefix_number: 786, diff_utc: -5 },
  { prefix_number: 787, diff_utc: -4 },
  { prefix_number: 801, diff_utc: -7 },
  { prefix_number: 802, diff_utc: -5 },
  { prefix_number: 803, diff_utc: -5 },
  { prefix_number: 804, diff_utc: -5 },
  { prefix_number: 805, diff_utc: -8 },
  { prefix_number: 806, diff_utc: -6 },
  { prefix_number: 807, diff_utc: -5 },
  { prefix_number: 808, diff_utc: -10 },
  { prefix_number: 809, diff_utc: -4 },
  { prefix_number: 810, diff_utc: -5 },
  { prefix_number: 812, diff_utc: -6 },
  { prefix_number: 813, diff_utc: -5 },
  { prefix_number: 814, diff_utc: -5 },
  { prefix_number: 815, diff_utc: -6 },
  { prefix_number: 816, diff_utc: -6 },
  { prefix_number: 817, diff_utc: -6 },
  { prefix_number: 818, diff_utc: -8 },
  { prefix_number: 819, diff_utc: -5 },
  { prefix_number: 825, diff_utc: -7 },
  { prefix_number: 828, diff_utc: -5 },
  { prefix_number: 829, diff_utc: -4 },
  { prefix_number: 830, diff_utc: -6 },
  { prefix_number: 831, diff_utc: -8 },
  { prefix_number: 832, diff_utc: -6 },
  { prefix_number: 835, diff_utc: -5 },
  { prefix_number: 843, diff_utc: -5 },
  { prefix_number: 845, diff_utc: -5 },
  { prefix_number: 847, diff_utc: -6 },
  { prefix_number: 848, diff_utc: -5 },
  { prefix_number: 849, diff_utc: -4 },
  { prefix_number: 850, diff_utc: -6 },
  { prefix_number: 856, diff_utc: -5 },
  { prefix_number: 857, diff_utc: -5 },
  { prefix_number: 858, diff_utc: -8 },
  { prefix_number: 859, diff_utc: -5 },
  { prefix_number: 860, diff_utc: -5 },
  { prefix_number: 862, diff_utc: -5 },
  { prefix_number: 863, diff_utc: -5 },
  { prefix_number: 864, diff_utc: -5 },
  { prefix_number: 865, diff_utc: -5 },
  { prefix_number: 867, diff_utc: -5 },
  { prefix_number: 868, diff_utc: -4 },
  { prefix_number: 869, diff_utc: -4 },
  { prefix_number: 870, diff_utc: -6 },
  { prefix_number: 872, diff_utc: -6 },
  { prefix_number: 873, diff_utc: -5 },
  { prefix_number: 876, diff_utc: -5 },
  { prefix_number: 878, diff_utc: -5 },
  { prefix_number: 901, diff_utc: -6 },
  { prefix_number: 902, diff_utc: -4 },
  { prefix_number: 903, diff_utc: -6 },
  { prefix_number: 904, diff_utc: -5 },
  { prefix_number: 905, diff_utc: -5 },
  { prefix_number: 906, diff_utc: -6 },
  { prefix_number: 907, diff_utc: -9 },
  { prefix_number: 908, diff_utc: -5 },
  { prefix_number: 909, diff_utc: -8 },
  { prefix_number: 910, diff_utc: -5 },
  { prefix_number: 912, diff_utc: -5 },
  { prefix_number: 913, diff_utc: -6 },
  { prefix_number: 914, diff_utc: -5 },
  { prefix_number: 915, diff_utc: -7 },
  { prefix_number: 916, diff_utc: -8 },
  { prefix_number: 902, diff_utc: -4 },
  { prefix_number: 903, diff_utc: -6 },
  { prefix_number: 904, diff_utc: -5 },
  { prefix_number: 905, diff_utc: -5 },
  { prefix_number: 906, diff_utc: -6 },
  { prefix_number: 907, diff_utc: -9 },
  { prefix_number: 908, diff_utc: -5 },
  { prefix_number: 909, diff_utc: -8 },
  { prefix_number: 910, diff_utc: -5 },
  { prefix_number: 912, diff_utc: -5 },
  { prefix_number: 913, diff_utc: -6 },
  { prefix_number: 914, diff_utc: -5 },
  { prefix_number: 915, diff_utc: -7 },
  { prefix_number: 916, diff_utc: -8 },
  { prefix_number: 917, diff_utc: -5 },
  { prefix_number: 918, diff_utc: -6 },
  { prefix_number: 919, diff_utc: -5 },
  { prefix_number: 920, diff_utc: -6 },
  { prefix_number: 925, diff_utc: -8 },
  { prefix_number: 927, diff_utc: -5 },
  { prefix_number: 928, diff_utc: -7 },
  { prefix_number: 929, diff_utc: -5 },
  { prefix_number: 931, diff_utc: -6 },
  { prefix_number: 935, diff_utc: -8 },
  { prefix_number: 936, diff_utc: -6 },
  { prefix_number: 937, diff_utc: -5 },
  { prefix_number: 939, diff_utc: -4 },
  { prefix_number: 940, diff_utc: -6 },
  { prefix_number: 941, diff_utc: -5 },
  { prefix_number: 947, diff_utc: -5 },
  { prefix_number: 949, diff_utc: -8 },
  { prefix_number: 951, diff_utc: -8 },
  { prefix_number: 952, diff_utc: -6 },
  { prefix_number: 954, diff_utc: -5 },
  { prefix_number: 956, diff_utc: -6 },
  { prefix_number: 957, diff_utc: -7 },
  { prefix_number: 959, diff_utc: -5 },
  { prefix_number: 970, diff_utc: -7 },
  { prefix_number: 971, diff_utc: -8 },
  { prefix_number: 972, diff_utc: -6 },
  { prefix_number: 973, diff_utc: -5 },
  { prefix_number: 975, diff_utc: -6 },
  { prefix_number: 978, diff_utc: -5 },
  { prefix_number: 979, diff_utc: -6 },
  { prefix_number: 980, diff_utc: -5 },
  { prefix_number: 984, diff_utc: -5 },
  { prefix_number: 985, diff_utc: -6 },
  { prefix_number: 989, diff_utc: -5 },
];
