<script setup>
import { ref, computed, defineProps } from "vue";

// import { getFormattedDate } from "@/utils";

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  clickLoading: {
    type: Boolean,
    required: true,
  },
});

const sortedData = computed(() => {
  if (!sortColumn.value) return props.data;

  return [...props.data].sort((a, b) => {
    let aVal = a[sortColumn.value];

    let bVal = b[sortColumn.value];

    if (sortColumn.value === "uploadDate") {
      aVal = new Date(a.createdAt);
      bVal = new Date(b.createdAt);
    }

    if (aVal < bVal) return sortDirection.value === "asc" ? -1 : 1;
    if (aVal > bVal) return sortDirection.value === "asc" ? 1 : -1;
    return 0;
  });
});

const sortColumn = ref("");
const sortDirection = ref("asc");
const currentPage = ref(1);
const itemsPerPage = ref(10);
const itemsPerPageOptions = [5, 10, 25, 50];

const sort = (column) => {
  if (sortColumn.value === column) {
    sortDirection.value = sortDirection.value === "asc" ? "desc" : "asc";
  } else {
    sortColumn.value = column;
    sortDirection.value = "asc";
  }
};

const totalPages = computed(() =>
  Math.ceil(sortedData.value.length / itemsPerPage.value)
);

const paginatedData = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;

  return sortedData.value.slice(start, end);
});

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const resetPage = () => {
  currentPage.value = 1;
};

const visiblePageNumbers = computed(() => {
  const totalPageCount = totalPages.value;
  const current = currentPage.value;
  const maxVisiblePages = 3; // Adjust this number to show more or fewer page numbers

  let startPage, endPage;
  if (totalPageCount <= maxVisiblePages) {
    // If total pages are less than max visible, show all pages
    startPage = 1;
    endPage = totalPageCount;
  } else {
    // Calculate start and end pages
    const middlePage = Math.floor(maxVisiblePages / 2);
    if (current <= middlePage) {
      startPage = 1;
      endPage = maxVisiblePages;
    } else if (current > totalPageCount - middlePage) {
      startPage = totalPageCount - maxVisiblePages + 1;
      endPage = totalPageCount;
    } else {
      startPage = current - middlePage;
      endPage = current + middlePage;
    }
  }

  // Generate page numbers
  let range = [];
  for (let i = startPage; i <= endPage; i++) {
    range.push(i);
  }

  // Add ellipsis if necessary
  if (startPage > 1) range.unshift("...");
  if (endPage < totalPageCount) range.push("...");

  // Always show first and last page
  if (startPage > 1) range.unshift(1);
  if (endPage < totalPageCount) range.push(totalPageCount);

  return range;
});

const goToPage = (page) => {
  currentPage.value = page;
};

const goToFirstPage = () => {
  currentPage.value = 1;
};

const goToLastPage = () => {
  currentPage.value = totalPages.value;
};
</script>

<template>
  <div v-if="clickLoading" class="text-center my-4">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <p class="mt-2">Loading data...</p>
  </div>
  <div v-else class="table-responsive p-0">
    <table class="table align-items-center justify-content-center mb-0">
      <thead>
        <tr>
          <th @click="sort('from')"
            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer">
            From Number
          </th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer">
            Replies
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in paginatedData" :key="index" :class="index % 2 === 0 ? 'bg-white' : 'bg-light'">
          <td class="w-15 px-4 text-center">
            {{ item.from }}
          </td>
          <td class="w-15 px-4 text-center">
            {{ item.message }}
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="totalPages > 1" class="pagination-controls d-flex justify-content-between align-items-center mt-3 px-5">
      <div>
        <span class="me-2">Rows per page:</span>
        <select v-model="itemsPerPage" @change="resetPage">
          <option v-for="option in itemsPerPageOptions" :key="option" :value="option">
            {{ option }}
          </option>
        </select>
      </div>
      <div class="pagination-nav">
        <button class="btn btn-sm btn-secondary me-1" @click="goToFirstPage" :disabled="currentPage === 1"
          title="First Page">
          &lt;&lt;
        </button>
        <button class="btn btn-sm btn-secondary me-1" @click="prevPage" :disabled="currentPage === 1"
          title="Previous Page">
          &lt;
        </button>
        <template v-for="pageNum in visiblePageNumbers" :key="pageNum">
          <button v-if="pageNum !== '...'" class="btn btn-sm me-1" :class="pageNum === currentPage
            ? 'btn-primary'
            : 'btn-secondary'
            " @click="goToPage(pageNum)">
            {{ pageNum }}
          </button>
          <span v-else class="mx-1">...</span>
        </template>
        <button class="btn btn-sm btn-secondary me-1" @click="nextPage" :disabled="currentPage === totalPages"
          title="Next Page">
          &gt;
        </button>
        <button class="btn btn-sm btn-secondary" @click="goToLastPage" :disabled="currentPage === totalPages"
          title="Last Page">
          &gt;&gt;
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pagination-controls select {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}

.pagination-controls button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-nav {
  display: flex;
  align-items: center;
}

.table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
  /* Light gray for even rows */
}

.table tbody tr:nth-child(odd) {
  background-color: #ffffff;
  /* White for odd rows */
}

.margin-left {
  margin-left: 12px;
}

.print-icon {
  justify-content: space-between;
}

.print-icon {
  display: flex;
  align-items: center;
}

.mouse-pointer {
  cursor: pointer;
}
</style>
