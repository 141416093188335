<script setup>
import {
  computed,
  ref,
  defineProps,
  watch,
  onMounted,
  onBeforeMount,
} from "vue";
import { useStore } from "vuex";
import { toE164, getStates } from "@/utils";
import { QuillEditor } from "@vueup/vue-quill";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ArgonInput from "@/components/Core/ArgonInput.vue";
import ArgonDropdown from "@/components/Core/ArgonDropdown.vue";
import { SegmentedMessage } from "sms-segments-calculator";
import { ScheduleTimezone } from "@/utils/timezone";

const {
  emitValidState,
  emitContentData,
  data: contentData,
  editMode,
} = defineProps({
  data: Object,
  emitContentData: Function,
  emitValidState: Function,
  editMode: Boolean,
});

const letterCount = ref(0); // Track letter count
const isChecked = ref(false);
const store = useStore();
const mediaData = computed(() => store.getters["mediaData/getMedia"]);
// const identities = computed(() =>
//   store.getters["identityData/getIdentities"].filter(
//     (itm) => itm.identityId !== 1
//   )
// );
const identities = computed(() => store.getters["identityData/getIdentities"]);

const assignedMedia = computed(
  () => store.getters["mediaData/getAssignedMedia"]
);
const associatedSipPeers = computed(
  () => store.getters["jobData/getAssociatedSipPeers"]
);
const siteInserviceNumbers = computed(
  () => store.getters["jobData/getSiteInserviceNumbers"]
);
const userId = computed(() => store.getters["user/currentUser"]?.id);
const user = computed(() => store.getters["user/currentUser"]);
const userList = computed(() => store.getters["user/getUsers"]);
// const purchasedPhoneNumbers = computed(() => store.getters["jobData/getPurchasedPhoneNumbers"]);
const quillEditor = ref(null);
const formData = ref({ ...contentData, jobName: contentData.name });
// const isUseMessageBuilderEnabled = ref(false);
const accountInfo = ref({});
const editorContent = ref("");
const segmented = ref({});
const segmentedPassed = ref({});
const timeSelection = ref("schedule");
const quickTestNumber = ref("");
// const selectedFile = ref(null);
// const userText = ref("");
const isStepValid = ref(false);
const isBitlyUrl = ref(false);
const isBitlyConverted = ref(false);
const bitlyUrl = ref("");
const isSelectedMedia = ref(false);
const jobAreaStates = ref([]);
const identityData = ref([]);

const localDateTime = ref(null);
// const files = ref([]);
const phoneNumberOptions = ref([
  { id: 1, name: "Existing Number" },
  { id: 2, name: "New Number" },
]);
const selectedPhoneNumberOption = ref(1);
const variables = [
  "{title}",
  "{first_name}",
  "{mid_name}",
  "{last_name}",
  "{address1}",
  "{address2}",
  "{city}",
  "{state}",
  "{zip}",
  "{email}",
  "{lead_phone}",
];

const getAssignedMedia = async (url) => {
  const media = mediaData.value.find((itm) =>
    itm.previewUrl.includes(url.split("?")[0])
  );
  await store.commit("mediaData/setAssignedMedia", media);
  return media;
};

const insertVariable = (variable) => {
  const quill = quillEditor.value.getQuill();
  const range = quill.getSelection(true);
  if (range) {
    quill.insertText(range.index, variable, "user");
    quill.setSelection(range.index + variable.length);
  }
};

onBeforeMount(async () => {
  await store.dispatch("mediaData/fetchMedia", { userId: userId.value });
  if (editMode)
    formData.value = {
      ...formData.value,
      isMMSEnabled: contentData.initialTemplateMedia !== null,
      existingNumber: contentData.adminPhone,
      initialTemplateMedia:
        contentData.initialTemplateMedia !== null
          ? getAssignedMedia(contentData.initialTemplateMedia)
          : null,
      identity: getIdentity(contentData.identityId),
    };
});

onMounted(() => {
  if (user.value.role !== "ADMIN") identityData.value = identities.value;
  jobAreaStates.value = [
    {
      name: "US National (USA)",
      code: "USA",
    },
    {
      name: "Canada National (CAN)",
      code: "CAN",
    },
    ...getStates(),
  ];
  store.dispatch("jobData/fetchAssociatedSipPeers");
  store.dispatch("jobData/fetchPurchasedPhoneNumbers");
  formData.value.existingNumbers = formData.value.existingNumbers || [];
  localDateTime.value = formData.value.startDate || null;
});

const getIdentity = (id) => {
  const identity = identities.value.find((itm) => itm?.identityId == id);
  return identity;
};

// Watch for changes in formData
watch(
  formData,
  (newValue) => {
    // Check if all required fields are filled
    // isStepValid.value = Object.values(formData).every((field) => !!field);
    if (selectedPhoneNumberOption.value === 1) {
      isStepValid.value = formData.value.isMMSEnabled
        ? [
            "adminPhone",
            "identity",
            "jobName",
            "startDate",
            "initialTemplateMedia",
            "initialTemplateMessage",
            "guidLink",
            "campaignID",
            "letters",
          ].every((field) => !!formData.value[field])
        : [
            "adminPhone",
            "identity",
            "jobName",
            "startDate",
            "initialTemplateMessage",
            "guidLink",
            "campaignID",
            "letters",
          ].every((field) => !!formData.value[field]);
    } else {
      isStepValid.value = [
        "adminPhone",
        "identity",
        "jobName",
        "jobArea",
        "startDate",
        "initialTemplateMessage",
        "guidLink",
        "campaignID",
        "letters",
      ].every((field) => !!formData.value[field]);
    }

    emitValidState(isStepValid.value);
    formData.value = newValue;

    emitContentData(newValue);
  },
  { deep: true }
);

watch(
  () => timeSelection.value,
  async (newValue) => {
    if (newValue === "now") {
      localDateTime.value = new Date();
      await convertToUTC();
    }
  }
);

// watch(
//   () => accountInfo.value,
//   async (newValue) => {
//     formData.value.identity = {};
//     identityData.value = identities.value.filter(
//       (item) => item.accountId === newValue.accountId
//     );
//   }
// );

watch(
  () => accountInfo.value,
  async (newValue) => {
    formData.value.identity = {};
    identityData.value = identities.value.filter((item) => {
      // Check if newValue.accountId is 46 or 2
      if (newValue.accountId === 46 || newValue.accountId === 2) {
        // Return true if either identityId is 1 OR accountId matches
        return item.identityId === 1 || item.accountId === newValue.accountId;
      }

      // Original condition for other cases
      return item.accountId === newValue.accountId;
    });
  }
);

watch(
  () => formData.value.initialTemplateMessage,
  async (newValue) => {
    if (newValue) {
      try {
        const cleanValue = newValue
          .replace(/<p><br><\/p>/g, "\n") // Replace empty paragraphs with newline
          .replace(/<p>(.*?)<\/p>/g, "$1\n") // Replace paragraphs with content + newline
          .replace(/\u200B/g, "") // Remove zero-width spaces
          .replace(/&amp;/g, "&") // Convert &amp; back to &
          .replace(/<[^>]*>/g, "");

        // If content is empty after cleaning, set everything to 0
        if (!cleanValue.trim()) {
          segmentedPassed.value = {
            passed: "",
            counts: 0,
            totalSize: 0,
          };
          return;
        }

        // Create segmented message with adjusted length calculation
        const segmentedMessage = new SegmentedMessage(cleanValue, "auto", true);
        segmented.value = segmentedMessage;
        console.log(segmented.value);

        // const actualCharacters = segmentedMessage.graphemes.filter((char) => {
        //   return !/[\s\u200B<>]/.test(char) && char !== "\n";
        // });

        // Calculate actual length considering HTML entities as single characters
        const actualCharacters = segmentedMessage.graphemes.filter((char) => {
          return !/[\s\u200B<>]/.test(char) && char !== "\n";
        });

        segmentedPassed.value = {
          passed: segmentedMessage.graphemes.join("").replace(/&amp;/g, "&"), // Convert &amp; to & in final output
          counts: segmentedMessage.segmentsCount,
          totalSize: actualCharacters.length,
        };

        // Only show alert if the actual length exceeds the maximum size
        if (letterCount.value > 160) {
          console.warn(
            `Warning: The message has been segmented into ${segmentedPassed.value.counts} parts.`
          );
          alert(
            `Your message is too long and has been split into ${segmentedPassed.value.counts} segments. Consider reducing the length to avoid additional costs or issues.`
          );
        }

        let processedContent = segmentedMessage.graphemes.join("");

        if (!processedContent.trim() || processedContent === "<p><br></p>") {
          segmentedPassed.value = {
            passed: "",
            counts: 0,
            totalSize: 0,
          };
          return;
        }

        // Improved regex for URL detection
        const urlRegex =
          /\b(?!(?:https?:\/\/|www\.))[a-z0-9-]+(?:\.[a-z0-9-]+)+(?:\/[^\s]*)?(?![.,:;])\b/gi;

        // // Function to check if a match is a valid URL
        // const isValidUrl = (match) => {
        //   // Ignore common abbreviations
        //   if (/^[a-z]\.[a-z]\.$/i.test(match)) return false;

        //   try {
        //     // Attempt to create a URL object
        //     new URL(match);

        //     // If it's a valid URL object, perform additional checks
        //     const urlParts = match.toLowerCase().split('.');

        //     // Check if the TLD is valid (you can expand this list as needed)
        //     const validTlds = ['com', 'org', 'net', 'edu', 'gov', 'io', 'ai', 'co', 'uk', 'de', 'fr', 'jp', 'ru', 'br', 'in', 'au', 'info', 'biz', 'me'];
        //     const tld = urlParts[urlParts.length - 1];

        //     // Allow IP addresses
        //     if (/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(tld)) {
        //       return true;
        //     }

        //     // Check for country-specific TLDs (e.g., .co.uk)
        //     if (urlParts.length > 2 && validTlds.includes(urlParts[urlParts.length - 2]) && tld.length === 2) {
        //       return true;
        //     }

        //     return validTlds.includes(tld);
        //   } catch (e) {
        //     // If URL is invalid, return false
        //     return false;
        //   }
        // };

        const urls = processedContent.match(urlRegex);

        if (urls && !isBitlyConverted.value) {
          // for (const url of urls) {
          //   try {
          //     const shortUrl = await shortenUrl(url);
          //     if (shortUrl)
          //       processedContent = processedContent.replace(url, shortUrl);
          //   } catch (error) {
          //     console.error(`Failed to shorten URL ${url}:`, error);
          //     // If shortening fails, we keep the original URL
          //   }
          // }
          isBitlyConverted.value = true;
        }

        formData.value.initialTemplateMessage = processedContent;
        editorContent.value = processedContent; // Update Quill editor content
        console.log("Processed content:", processedContent);
      } catch (error) {
        console.error("Error processing message:", error);
      }
    } else {
      segmentedPassed.value = "";
      formData.value.guidLink = "Bitly";
      formData.value.campaignID = "Bitly";
    }
  },
  { deep: true, immediate: true }
);

const onIdentitySelect = (identity) => {
  formData.value.identity = identity;

  const filteredUser = userList.value?.find(
    (selectUser) => Number(selectUser.accountId) === Number(user.value.id)
  );
  const site = associatedSipPeers.value?.find(
    (item) =>
      item.PeerName == identity.identityName &&
      item.SiteId ==
        (Object.keys(accountInfo.value || {}).length > 0
          ? accountInfo.value.siteId
          : filteredUser?.siteId)
  );

  if (site) {
    store.dispatch("jobData/fetchSiteInserviceNumbers", {
      siteId: site.SiteId,
      sippeerId: site.PeerId,
    });
  }
};

// async function shortenUrl(longUrl) {
//   // Add https:// to the URL if it doesn't have a protocol
//   try {
//     const fullUrl = longUrl.startsWith("http") ? longUrl : `https://${longUrl}`;

//     const response = await fetch("https://api-ssl.bitly.com/v4/bitlinks", {
//       method: "POST",
//       headers: {
//         Authorization: `Bearer ${process.env.VUE_APP_BITLY_TOKEN}`, // Replace with your actual Bitly access token
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         long_url: fullUrl,
//         domain: "20win24.com", // You can change this if you have a custom domain
//       }),
//     });

//     if (!response.ok) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }

//     const data = await response.json();

//     // Remove the protocol from the shortened URL to match the original format
//     return data.link.replace(/^https?:\/\//, "");
//   } catch (err) {
//     console.log(err);
//   }
// }

const selectPhoneNumberOption = (option) => {
  selectedPhoneNumberOption.value = option.id;

  if (option.id === 1) {
    store.dispatch("jobData/fetchPurchasedPhoneNumbers");
  }
};

const convertToUTC = (localDateString) => {
  if (isChecked.value) {
    if (localDateString) {
      let diffTime;
      // Get the prefix from existingNumber (first 3 digits)
      const numberPrefix = parseInt(
        formData.value.existingNumber.substring(0, 3)
      );

      // Find matching timezone data
      const timezoneData = ScheduleTimezone.find(
        (tz) => tz.prefix_number === numberPrefix
      );

      diffTime = timezoneData ? timezoneData.diff_utc : -5;

      // Parse the local date string
      const localDate = new Date(localDateString);

      // Add 18 hours to convert from UTC-9 to UTC+9
      const utcDate = new Date(
        localDate.getTime() - (5 + diffTime) * 60 * 60 * 1000
      );

      // Format the UTC date as an ISO string
      formData.value.startDate = utcDate.toISOString();

      console.log("Selected local time (UTC-9):", localDate.toLocaleString());
      console.log("Converted UTC time:", formData.value.startDate);
    } else {
      const localDate = new Date();

      // Get the timezone offset in minutes

      // Convert local time to UTC by adding the timezone offset
      const utcDate = new Date(localDate.getTime());

      // Format the UTC date as an ISO string
      formData.value.startDate = utcDate.toISOString();

      console.log("Selected local time:", localDate.toLocaleString());
      console.log("Converted UTC time:", formData.value.startDate);
    }
  } else {
    if (localDateString) {
      // Parse the local date string
      const localDate = new Date(localDateString);

      // Get the timezone offset in minutes

      // Convert local time to UTC by adding the timezone offset
      const utcDate = new Date(localDate.getTime());

      // Format the UTC date as an ISO string
      formData.value.startDate = utcDate.toISOString();

      console.log("Selected local time:", localDate.toLocaleString());
      console.log("Converted UTC time:", formData.value.startDate);
    } else {
      const localDate = new Date();

      // Get the timezone offset in minutes

      // Convert local time to UTC by adding the timezone offset
      const utcDate = new Date(localDate.getTime());

      // Format the UTC date as an ISO string
      formData.value.startDate = utcDate.toISOString();

      console.log("Selected local time:", localDate.toLocaleString());
      console.log("Converted UTC time:", formData.value.startDate);
    }
  }
};

const onExistingNumberSelect = (existingNumber) => {
  formData.value.existingNumber = existingNumber;
  formData.value.adminPhone = existingNumber;
};

const onQuickTestClick = async () => {
  // Parse the HTML content to plain text
  const parser = new DOMParser();
  const doc = parser.parseFromString(
    formData.value.initialTemplateMessage,
    "text/html"
  );
  const formattedPhoneNumber = toE164(quickTestNumber.value, "1");

  // Get all paragraphs from the parsed document
  const paragraphs = Array.from(doc.body.querySelectorAll("p"));

  // Initialize an array to store formatted paragraphs
  const formattedParagraphs = [];

  // Iterate through each paragraph, add its text content to the array with line breaks
  paragraphs.forEach((paragraph) => {
    formattedParagraphs.push(paragraph.textContent);
  });

  // Join formatted paragraphs with line breaks
  const testMessage = formattedParagraphs.join("\n");

  console.log("TextArea content--->", editorContent, testMessage);

  if (formattedPhoneNumber) {
    await store.dispatch("jobData/testQuick", {
      to: [formattedPhoneNumber],
      from: formData.value.adminPhone,
      text: editorContent.value,
      media: formData.value.isMMSEnabled
        ? formData.value.initialTemplateMedia
        : null,
    });
  }
};

// const urlConverter = async () => {
//   if (bitlyUrl.value) {
//     isBitlyUrl.value = true;
//     const url = await shortenUrl(bitlyUrl.value);
//     isBitlyUrl.value = false;

//     bitlyUrl.value = url;
//   }

//   // if (bitlyUrl.value) {
//   //   isBitlyUrl.value = true;
//   //   const randomStr = Math.random().toString(36).substring(2, 10); // generates 8 char string
//   //   const url = `${process.env.VUE_APP_LINK_API_URL}/campaign/` + randomStr;

//   //   formData.value.campaignID = bitlyUrl.value
//   //   formData.value.guidLink = randomStr
//   //   bitlyUrl.value = url;

//   // }

// };

// const urlConverter = async () => {
//   if (bitlyUrl.value) {
//     isBitlyUrl.value = true;
//     const url = await shortenUrl(bitlyUrl.value);
//     isBitlyUrl.value = false;

//     bitlyUrl.value = url;
//   }
// };

const base1024Chars =
  "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ" + "0123456789";

const base1024Encode = (num) => {
  const base = base1024Chars.length;
  let encoded = [];

  // Handle case when num is 0
  if (num === 0) return base1024Chars[0].padStart(8, base1024Chars[0]);

  while (num > 0) {
    const rem = num % base;
    encoded.unshift(base1024Chars[rem]);
    num = Math.floor(num / base);
  }

  // Pad with first character if less than 8 chars
  return encoded.join("").padStart(8, base1024Chars[0]);
};

const urlConverter = () => {
  if (bitlyUrl.value) {
    isBitlyUrl.value = true;

    // Increased range to ensure we get enough characters
    const randomNum = Math.floor(Math.random() * 1000000000);
    let randomStr = base1024Encode(randomNum);

    // If longer than 8 chars, take the last 8
    if (randomStr.length > 8) {
      randomStr = randomStr.slice(-8);
    }

    isBitlyUrl.value = false;

    const getApiUrl = computed(() => {
      if (!user.value?.domainName) return process.env.VUE_APP_LINK_API_URL;

      if (user.value.domainName === "e.djt25.com") {
        return process.env.VUE_APP_DJT_LINK_API_URL;
      }

      return process.env.VUE_APP_LINK_API_URL;
    });

    const url = `${getApiUrl.value}/` + randomStr;
    // const url = `${process.env.VUE_APP_LINK_API_URL}/` + randomStr;

    const payload = {
      campaignid: bitlyUrl.value,
      guidlink: randomStr,
    };

    store.dispatch("campaignData/fetchLink", payload);

    formData.value.campaignID = bitlyUrl.value;
    formData.value.guidLink = randomStr;
    bitlyUrl.value = url;
  }
};

// const onHandleFileUpload = () => {
//   // Update selectedFile with the chosen file
//   selectedFile.value = event.target.files[0];
// };

// const onUploadFileClick = () => {};

const onMMSEnabledClick = () => {
  formData.value.isMMSEnabled = !formData.value.isMMSEnabled;
  store.commit("mediaData/setAssignedMedia", null);
  // formData.value.initialTemplateMedia = "";
};

// const sanitizedHtml = computed(() => {
//   return DOMPurify.sanitize(formData.value.initialTemplateMessage);
// });

// const updateContent = (content) => {
//   // formData.value.initialTemplateMessage = content;
//   const cleanContent = content === "<p><br></p>" ? "" : content;
//   formData.value.initialTemplateMessage = cleanContent;

//   letterCount.value = cleanContent.replace(/<[^>]*>/g, "").length-1;
// };

// const updateContent = (content) => {
//   const cleanContent = content === "<p><br></p>" ? "" : content;
//   formData.value.initialTemplateMessage = cleanContent;

//   // Remove HTML tags and count actual characters
//   const textOnly = cleanContent.replace(/<[^>]*>/g, "").trim();
//   letterCount.value = textOnly.length;
// };

const updateContent = (content) => {
  const cleanContent = content === "<p><br></p>" ? "" : content;
  formData.value.initialTemplateMessage = cleanContent;

  // Remove HTML tags and decode HTML entities before counting
  const textOnly = cleanContent
    .replace(/<[^>]*>/g, "") // Remove HTML tags
    .replace(/&[a-zA-Z]+;|&#[0-9]+;/g, "x") // Replace HTML entities with single character
    .trim();

  letterCount.value = textOnly.length;
  formData.value.letters = letterCount.value;
};

watch(
  assignedMedia,
  (newAssignedMedia) => {
    if (
      newAssignedMedia &&
      formData.value.identity &&
      formData.value.isMMSEnabled
    ) {
      if (newAssignedMedia.thumbnailUrl)
        formData.value.initialTemplateMedia = newAssignedMedia.thumbnailUrl;
    } else {
      if (!contentData.isMMSEnabled) formData.value.initialTemplateMedia = null;
    }
  },
  { immediate: true }
);

const onBrowse = async () => {
  await store.commit("mediaData/setSelectedIdentity", formData.value.identity);
  await store.commit("mediaData/toggleAssignMediaPanel");
  isSelectedMedia.value = true;
};

// const onFileUpload = ($event) => {
//   const target = $event?.EventTarget;
//   if (target && EventTarget.files) {
//     const fileList = Array.from(EventTarget.files);
//     const filesArray = fileList.map((file) => ({
//       file: file,
//       title: file.name,
//     }));
//     files.value = filesArray;
//   }
// };

// const onUseMessageBuilderEnabledClick = () => {};

const onJobAreaStateSelect = (jobArea) => {
  formData.value.jobArea = jobArea;
  formData.value.allAreaCodes = [];
};

const onAreaCodeSelect = (areaCode) => {
  const index = formData.value.allAreaCodes?.indexOf(areaCode);
  if (index > -1) {
    formData.value.allAreaCodes?.splice(index, 1);
  } else {
    formData.value.allAreaCodes?.push(areaCode);
  }
};

const onAgencyChange = async (userInfo) => {
  accountInfo.value = userInfo;
};

const handleClick = () => {
  isChecked.value = !isChecked.value; // Toggle the value
};
</script>

<template>
  <div class="row">
    <div class="col-lg-6 col-12">
      <div class="row">
        <div class="col-12">
          <!-- <label for="identity-input" class="form-control-label">Identity:</label>
          <argon-input type="text" v-model="formData.identity" /> -->
          <div v-if="user?.role === 'ADMIN'">
            <label for="identity-name-input" class="form-control-label"
              >Agency:</label
            >
            <argon-dropdown class="me-1">
              <template v-slot:title>
                <argon-button color="light" class="dropdown-toggle">
                  {{ accountInfo?.userName || "Agency" }}
                </argon-button>
              </template>
              <a
                v-for="(userinfo, index) in userList"
                class="dropdown-item d-flex align-items-center"
                href="#"
                :key="index"
                @click="onAgencyChange(userinfo)"
              >
                <label :for="userinfo.userName" class="mb-0">{{
                  userinfo.userName
                }}</label>
              </a>
            </argon-dropdown>
            <!-- <div v-if="errors.agency" class="text-danger mt-1 small">
              {{ errors.agency }}
            </div> -->
          </div>
          <label>Identity:</label>
          <argon-dropdown class="">
            <template v-slot:title>
              <argon-button color="light" class="dropdown-toggle">
                {{ formData.identity?.identityName || "Identity" }}
              </argon-button>
            </template>
            <a
              v-for="(identity, index) in identityData"
              :key="index"
              class="dropdown-item d-flex align-items-center"
              @click="onIdentitySelect(identity)"
            >
              <label class="mb-0">{{ identity.identityName }}</label>
            </a>
          </argon-dropdown>
        </div>
        <div class="col-12">
          <label for="job-name-input" class="form-control-label"
            >Job Name:</label
          >
          <argon-input type="text" v-model="formData.jobName" />
        </div>
        <div class="col-12">
          <div v-for="option in phoneNumberOptions" :key="option.id">
            <label>
              <input
                type="radio"
                :value="option.id"
                :checked="selectedPhoneNumberOption === option.id"
                @change="selectPhoneNumberOption(option)"
              />
              {{ option.name }}
            </label>
          </div>
        </div>
        <div v-if="selectedPhoneNumberOption === 1">
          <argon-dropdown class="">
            <template v-slot:title>
              <argon-button color="light" class="dropdown-toggle">
                {{ formData.existingNumber || "Select Phone Number" }}
              </argon-button>
            </template>
            <a
              v-for="(phoneNumber, index) in siteInserviceNumbers"
              :key="index"
              class="dropdown-item d-flex align-items-center"
              @click="onExistingNumberSelect(phoneNumber)"
            >
              <label class="mb-0">{{ phoneNumber }}</label>
            </a>
            <!-- Changed to use site inservice numbers instead of purchased numbers
            <a
              v-for="(phoneNumber, index) in purchasedPhoneNumbers"
              :key="index"
              class="dropdown-item d-flex align-items-center"
              @click="onExistingNumberSelect(phoneNumber.FullNumber)"
            >
              <label class="mb-0">{{ phoneNumber.FullNumber }}</label>
            </a> -->
            <!-- <template v-slot:title>
              <argon-button color="light" class="w-100 dropdown-toggle">
                {{ formData.existingNumbers?.join(", ") }}
              </argon-button>
            </template>
            <a
              v-for="(existingNumber, index) in purchasedPhoneNumbers"
              :key="index"
              class="dropdown-item d-flex align-items-center"
            >
              <input
                type="checkbox"
                :id="'existingNumber-' + index"
                value="formData?.existingNumbers.includes(existingNumber)"
                @change="onExistingNumberSelect(existingNumber?.FullNumber)"
              />
              <label class="mb-0">{{ existingNumber?.FullNumber }}</label>
            </a> -->
          </argon-dropdown>
        </div>
        <div v-if="selectedPhoneNumberOption === 2" class="col-12">
          <label for="job-area-input" class="form-control-label"
            >Job Area:</label
          >
          <p>
            Your numbers (DIDs) for your agents will be automatically leased to
            you based on the state (and area codes) you select. If there are no
            available numbers where you have selected, the next best available
            number from the state, then country will be chosen. If US or Canada
            National is selected, the numbers will be leased from random area
            codes in that country.
          </p>
          <!-- <argon-input type="text" v-model="formData.jobArea" /> -->
          <argon-dropdown class="">
            <template v-slot:title>
              <argon-button color="light" class="w-100 dropdown-toggle">
                {{ formData?.jobArea?.name || "US National (USA)" }}
              </argon-button>
            </template>
            <a
              v-for="(jobArea, index) in jobAreaStates"
              :key="index"
              class="dropdown-item d-flex align-items-center"
              @click="onJobAreaStateSelect(jobArea)"
            >
              <label class="mb-0">{{ jobArea?.name }}</label>
            </a>
          </argon-dropdown>
        </div>
        <div
          v-if="
            selectedPhoneNumberOption === 2 &&
            formData.jobArea &&
            !['USA', 'CAN'].includes(formData.jobArea?.code)
          "
          class="col-12"
        >
          <label for="all-area-codes-input" class="form-control-label">
            All Area Codes:
          </label>
          <!-- <argon-input type="text" v-model="allAreaCodes" /> -->
          <argon-dropdown class="">
            <template v-slot:title>
              <argon-button color="light" class="w-100 dropdown-toggle">
                {{ formData.allAreaCodes?.join(", ") }}
              </argon-button>
            </template>
            <a
              v-for="(areaCode, index) in formData.jobArea?.areaCodes"
              :key="index"
              class="dropdown-item d-flex align-items-center"
            >
              <input
                type="checkbox"
                :id="'areaCode-' + index"
                value="formData?.allAreaCodes.includes(areaCode)"
                @change="onAreaCodeSelect(areaCode)"
              />
              <label class="mb-0">{{ areaCode }}</label>
            </a>
          </argon-dropdown>
        </div>
        <!-- <div class="col-12 mt-2">
          <label for="all-area-codes-input" class="form-control-label">
            Message Segmentation info:
          </label>
          <div class="sms-info rounded p-4 bg-navy-900 text-white w-full max-w-2xl">
            <div class="grid gap-3">
              <div class="segment-count">
                <span class="text-gray-400 text-sm font-medium">Number of segments: {{ segmentedPassed.counts }}</span>
              </div>
              <div class="segment-count">
                <span class="text-gray-400 text-sm font-medium">Number of characters: {{ segmentedPassed.totalSize }}</span>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="col-12">
          <label for="upload-file-input" class="form-control-label">Upload File:</label>
          <div class="row">
            <div class="col-6">
              <argon-input
                type="file"
                @change="onHandleFileUpload"
                accept="image/*, video/*"
              />
            </div>
            <div class="col-6 d-flex justify-content-end align-items-start">
              <argon-button
                color="success"
                size="sm"
                class="ms-2"
                @click="onUploadFileClick"
                >Upload File</argon-button
              >
            </div>
          </div>
        </div>
        <div class="col-12">
          <label for="user-text-input" class="form-control-label">User Text:</label>
          <argon-input type="text" v-model="userText" />
        </div> -->
      </div>
    </div>
    <div class="col-lg-6 col-12">
      <div class="d-block mb-4">
        <h6 class="mb-0">MMS Enabled:</h6>
        <div class="form-check form-switch my-auto">
          <input
            class="mt-1 form-check-input"
            type="checkbox"
            id="mmsEnabled"
            :checked="formData.isMMSEnabled"
            @click="onMMSEnabledClick"
          />
        </div>
      </div>
      <!-- Consider later
      <div class="d-block">
        <h6 class="mb-0">Use Message Builder:</h6>
        <div class="form-check form-switch my-auto">
          <input
            class="mt-1 form-check-input"
            type="checkbox"
            id="useMessageBuilder"
            :checked="isUseMessageBuilderEnabled"
            @click="onUseMessageBuilderEnabledClick"
          />
        </div>
      </div> -->
      <div v-if="formData.isMMSEnabled" class="d-block mt-4">
        <label class="form-control-label">Initial Template Media:</label>
        <!-- <argon-input
          ref="file"
          type="file"
          id="fileInput"
          @change="(e) => onFileUpload(e)"
          accept="image/*, video/*"
          :multiple="true"
        /> -->
        <div class="d-flex">
          <div class="flex-grow-1" style="display: none">
            <argon-input
              readonly
              v-model="formData.initialTemplateMedia"
              class="mb-0"
            />
          </div>
          <div>
            <argon-button
              class="mx-2 px-2"
              :disabled="!formData.identity"
              @click.prevent="onBrowse()"
            >
              Browse
            </argon-button>
          </div>
          <div v-if="formData.initialTemplateMedia">
            <div
              v-if="assignedMedia?.mediaType === 'IMAGE'"
              class="media-container"
            >
              <img
                :src="formData.initialTemplateMedia"
                alt="Image"
                class="media"
              />
            </div>
            <div
              v-else-if="assignedMedia?.mediaType === 'VIDEO'"
              class="media-container"
            >
              <video controls class="media">
                <source :src="formData.initialTemplateMedia" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
        <div v-if="!formData.identity" class="text-red">
          You must select an identity to assign a media
        </div>
      </div>
      <div class="d-block mt-4">
        <label class="form-control-label">Initial Template Message:</label>
        <QuillEditor
          ref="quillEditor"
          theme="snow"
          id="initialTemplateMessageEditor"
          :content="editorContent"
          @update:content="updateContent"
          content-type="html"
        />
        <!-- Letter count display -->
        <label class="letter-count" :class="{ 'text-red': letterCount > 160 }">
          Characters used {{ letterCount }}/160 + variables ({{
            letterCount < 1 ? 0 : letterCount > 160 ? 2 : 1
          }}
          segment)
        </label>
      </div>
      <label class="form-control-label d-block mt-4">Dynamic Data:</label>
      <div class="d-flex mt-1 flex-wrap message-variable-container gap-1">
        <argon-button
          v-for="(variable, index) in variables"
          :key="index"
          color="primary"
          class="px-2 py-1 me-2 mb-2 border-radius-2xl"
          @click="insertVariable(variable)"
        >
          {{ variable }}
        </argon-button>
      </div>

      <div class="d-block mt-4">
        <label for="job-name-input" class="form-control-label"
          >Short Link Generator: You must use short link generator to get short
          link</label
        >
        <argon-input type="text" v-model="bitlyUrl" />
        <argon-button
          color="success"
          size="sm"
          :disabled="isBitlyUrl"
          @click="urlConverter"
        >
          Shorten URL
        </argon-button>
      </div>
      <div class="d-block mt-4">
        <div style="display: flex; align-items: center; gap: 10px">
          <label
            class="flex items-center cursor-pointer items-center"
            style="
              display: flex;
              align-items: center;
              justify-items: center;
              gap: 2px;
            "
          >
            <input
              type="radio"
              v-model="timeSelection"
              value="schedule"
              class="appearance-none h-4 w-full border border-gray-300 checked:bg-blue-600 checked:border-transparent focus:outline-none"
            />
            <span class="text-gray-700" style="font-size: 14px">Schedule</span>
          </label>
          <label
            class="flex items-center cursor-pointer items-center"
            style="
              display: flex;
              align-items: center;
              justify-items: center;
              gap: 2px;
            "
          >
            <input
              type="radio"
              v-model="timeSelection"
              value="now"
              class="appearance-none h-4 w-full border border-gray-300 checked:bg-blue-600 checked:border-transparent focus:outline-none"
            />
            <span class="text-gray-700" style="font-size: 14px">Now</span>
          </label>
        </div>
        <div
          class="form-check form-switch my-auto"
          style="display: flex; align-items: center; gap: 4px"
        >
          <input
            class="mt-0 form-check-input"
            type="checkbox"
            id="changeTimezoneEnabled"
            v-model="isChecked"
            @click="handleClick"
          />
          <label class="form-control-label m-0">Adjust to Timezone </label
          ><label
            class="form-control-label m-0 text-gray-700"
            style="font-size: 13px"
            >(Only applies to jobs scheduled in advance)</label
          >
        </div>
        <div v-if="timeSelection === 'schedule'">
          <label class="form-control-label">Start Time:</label>

          <VueDatePicker
            v-model="localDateTime"
            format="yyyy-MM-dd HH:mm:ss"
            @update:modelValue="convertToUTC"
            time-picker-inline
          ></VueDatePicker>
        </div>
      </div>
      <div class="d-block mt-4">
        <label for="quick-test-number-input" class="form-control-label">
          Quick Test Number:
        </label>
        <div class="row">
          <div class="col-6">
            <argon-input type="text" v-model="quickTestNumber" />
          </div>
          <div class="col-6 d-flex justify-content-end align-items-start">
            <argon-button
              color="success"
              size="sm"
              class="ms-2"
              @click="onQuickTestClick"
            >
              Quick Test
            </argon-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
#initialTemplateMessageEditor {
  min-height: 185px;
}
.form-control:disabled,
.form-control[readonly] {
  background: transparent !important;
  opacity: 1;
}
.text-red {
  color: red;
}
.media {
  width: 300px;
}
.sms-info {
  background-color: #1e293b;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
}

.parsed-message {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 0.75rem;
}

.segment-count {
  padding-top: 0.25rem;
}

/* If you want the container to be responsive */
@media (max-width: 640px) {
  .sms-info {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.text-red {
  color: red;
}
</style>
