<script setup>
import { onMounted, computed, ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ArgonInput from "@/components/Core/ArgonInput.vue";
import ArgonDropdown from "@/components/Core/ArgonDropdown.vue";
import { useToast } from "vue-toast-notification";

const store = useStore();
const router = useRouter();
const listId = computed(() => router.currentRoute.value.params.id);
const recipients = computed(() => store.getters["p2pChatData/getRecipients"]);
const selectedRecipient = computed(
  () => store.getters["p2pChatData/getSelectedRecipient"]
);
const chats = computed(() => store.getters["p2pChatData/getChats"]);
const isLoading = computed(() => store.getters["p2pChatData/isLoading"]);
const siteInserviceNumbers = computed(
  () => store.getters["jobData/getSiteInserviceNumbers"]
);
const getIdentityId = computed(
  () => store.getters["p2pChatData/getIdentityId"]
);
const identities = computed(() => store.getters["identityData/getIdentities"]);
const userList = computed(() => store.getters["user/getUsers"]);
const user = computed(() => store.getters["user/currentUser"]);
const associatedSipPeers = computed(
  () => store.getters["jobData/getAssociatedSipPeers"]
);

const selectedRecord = ref(null);
const currentIndex = ref(-1);
const sentRecipients = ref(new Set()); // Track sent recipients

const replyText = ref("");
let currentPage = ref(1);
const perPage = ref(12);
const formData = ref({});
const accountInfo = ref({});
const toast = useToast();

const paginatedRecipients = computed(() => {
  const start = (currentPage.value - 1) * perPage.value;
  const end = start + perPage.value;
  return recipients?.value?.slice(start, end);
});

const totalPages = computed(() => {
  return Math.ceil(recipients?.value?.length / perPage.value);
});

onMounted(async () => {
  await store.dispatch("p2pChatData/fetchRecipients", { listId: listId.value });
  store.dispatch("jobData/fetchAssociatedSipPeers");
});

onBeforeMount(async () => {
  const identityName =
    identities.value.find(
      (identity) => identity.identityId === getIdentityId.value
    )?.identityName || null;

  const filteredUser = userList.value?.find(
    (selectUser) => Number(selectUser.accountId) === Number(user.value.id)
  );
  const site = associatedSipPeers.value?.find(
    (item) =>
      item.PeerName == identityName &&
      item.SiteId ==
        (Object.keys(accountInfo.value || {}).length > 0
          ? accountInfo.value.siteId
          : filteredUser?.siteId)
  );
  if (site) {
    store.dispatch("jobData/fetchSiteInserviceNumbers", {
      siteId: site.SiteId,
      sippeerId: site.PeerId,
    });
  }
});

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const onRecipientSelect = async (recipient, index) => {
  selectedRecord.value = recipient;
  currentIndex.value = index;
  // store.commit("p2pChatData/setChats", []);
  // store.commit("p2pChatData/setSelectedRecipient", recipient);
  // store.dispatch("p2pChatData/fetchChatHistory", {
  //   listId: listId.value,
  //   recipient,
  // });
};

const sendText = async () => {
  if (formData.value.adminPhone && selectedRecord.value) {
    const generateJobId = () => {
      return [...Array(20)]
        .map(() => Math.floor(Math.random() * 16).toString(16))
        .join("");
    };

    const jobId = generateJobId();
    const fromPhoneNumber = `+1${formData.value.adminPhone}`;

    await store.dispatch("p2pChatData/testQuick", {
      from: fromPhoneNumber,
      to: [selectedRecord.value],
      text: replyText.value,
      tag: "reply from dashboard",
      jobId: jobId,
    });

    toast.success("Message sent successfully!", { position: "top-right" });

    if (selectedRecipient.value) {
      sentRecipients.value.add(selectedRecord.value);
    }

    if (currentIndex.value < paginatedRecipients.value.length - 1) {
      const nextIndex = currentIndex.value + 1;
      const nextRecipient = paginatedRecipients.value[nextIndex];
      await onRecipientSelect(nextRecipient, nextIndex);
    }

    //   await store.dispatch("p2pChatData/fetchChatHistory", {
    //     // listId: listId.value,
    //     from: fromPhoneNumber,
    //     jobId: jobId,
    //     recipient: selectedRecipient.value,
    //   });
    //   replyText.value = "";
  } else {
    toast.warning("Phone Number is required.", { position: "top-right" });
  }
};

const onExistingNumberSelect = (existingNumber) => {
  formData.value.existingNumber = existingNumber;
  formData.value.adminPhone = existingNumber;
};
</script>

<template>
  <div class="py-4 container-fluid chat-details-container">
    <div class="row h-100">
      <div class="col-12 d-flex flex-column h-100">
        <h2 class="text-white mb-2">Chat Details</h2>
        <div class="row flex-grow-1">
          <div class="col-3">
            <div class="card min-vh-50 h-100">
              <div class="card-header">Chat List</div>
              <div v-if="isLoading" class="text-center my-4">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <p class="mt-2">Loading data...</p>
              </div>
              <div v-else class="card-body">
                <argon-dropdown class="">
                  <template v-slot:title>
                    <argon-button color="light" class="dropdown-toggle">
                      {{ formData.existingNumber || "Select Phone Number" }}
                    </argon-button>
                  </template>
                  <a
                    v-for="(phoneNumber, index) in siteInserviceNumbers"
                    :key="index"
                    class="dropdown-item d-flex align-items-center"
                    @click="onExistingNumberSelect(phoneNumber)"
                  >
                    <label class="mb-0">{{ phoneNumber }}</label>
                  </a></argon-dropdown
                >
                <div class="row">
                  <div
                    class="col-12 p-2 cursor-pointer d-flex justify-content-between align-items-center"
                    v-for="(item, index) in paginatedRecipients"
                    :key="index"
                    @click.prevent="onRecipientSelect(item, index)"
                    :class="{
                      'selected-recipient': selectedRecipient.includes(item),
                      'visited-recipient':
                        selectedRecipient.includes(item) &&
                        index < currentIndex,
                    }"
                  >
                    <div
                      :class="{
                        'text-red': selectedRecipient.includes(item),
                        'text-gray':
                          selectedRecipient !== item && index < currentIndex,
                      }"
                    >
                      <i class="fas fa-user"></i>
                      {{ item }}
                    </div>

                    <div v-if="sentRecipients.has(item)">
                      <i class="fas fa-lock"></i>
                    </div>
                    <!-- <div>
                      <i class="fas fa-lock"></i>
                    </div> -->
                  </div>
                </div>
                <div class="d-flex justify-content-between pagination-controls">
                  <argon-button
                    @click="prevPage"
                    :disabled="currentPage === 1"
                    class="pagination-button"
                  >
                    <i class="fas fa-chevron-left"></i>
                  </argon-button>
                  <span class="pagination-info"
                    >{{ currentPage }} / {{ totalPages }}</span
                  >
                  <argon-button
                    @click="nextPage"
                    :disabled="currentPage === totalPages"
                    class="pagination-button"
                  >
                    <i class="fas fa-chevron-right"></i>
                  </argon-button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-9">
            <div class="card min-vh-50 h-100">
              <div class="card-header">
                <i class="fas fa-user"></i>
                {{ selectedRecord || "" }}
              </div>
              <div class="card-body">
                <div class="chat-box">
                  <div
                    v-for="(chat, index) in chats"
                    :key="index"
                    :class="{
                      'chat-message': true,
                      'chat-right': chat.to === selectedRecord,
                      'chat-left': chat.from === selectedRecord,
                    }"
                    v-html="chat.message"
                  ></div>
                </div>
              </div>
              <div class="card-footer chat-input">
                <argon-input class="send-text" v-model="replyText" />
                <argon-button
                  color="success"
                  size="sm"
                  class="send-button"
                  @click.prevent="sendText()"
                >
                  Send
                </argon-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chat-details-container {
  height: 100vh;
}

.chat-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.pagination-info {
  margin: 0 10px;
  font-weight: bold;
}

.pagination-button {
  padding: 5px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination-button:not(:disabled):hover {
  background-color: #0056b3;
}

.chat-message {
  width: 80%;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
}

.chat-right {
  background-color: royalblue;
  color: white;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  align-self: flex-end;
}

.chat-left {
  background-color: red;
  color: white;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  align-self: flex-start;
}

.chat-input {
  width: 100%;
  display: flex;
  align-items: center;
}

.chat-input .send-text {
  flex-grow: 1;
  margin-bottom: 0;
}

.chat-input .send-button {
  margin-left: 10px;
}

.visited-recipient {
  background-color: #f5f5f5;
  transition: all 0.3s ease;
}

.text-gray {
  color: #808080;
}
</style>
