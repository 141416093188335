<script setup>
import { ref, defineProps, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import { getFormattedDate, getTooltip24hTime } from "@/utils";
import DeleteConfirmationModal from "@/components/Modal/index.vue";
import Tooltip from "@/components/Tooltip/index.vue";
import DateTooltip from "@/components/Tooltip/DateTooltip.vue";

const props = defineProps({
  headings: {
    type: Array,
    required: true,
  },
  data: {
    type: Array,
    required: true,
  },
  itemClick: {
    type: Function,
    required: true,
  },
  onClickDelete: {
    type: Function,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
});

const router = useRouter();
const store = useStore();
const identities = computed(() => store.getters["identityData/getIdentities"]);

const sortColumn = ref("");
const sortDirection = ref("asc");
const currentPage = ref(1);
const itemsPerPage = ref(10);
const deleteId = ref(-1);
const itemsPerPageOptions = [5, 10, 25, 50];
const showDeleteModal = ref(false);

const sort = (column) => {
  if (sortColumn.value === column) {
    sortDirection.value = sortDirection.value === "asc" ? "desc" : "asc";
  } else {
    sortColumn.value = column;
    sortDirection.value = "asc";
  }
};

const deleteUser = (id) => {
  showDeleteModal.value = true;
  deleteId.value = id;
};

const onDelete = async () => {
  props.onClickDelete(deleteId.value);
  showDeleteModal.value = false;
};

const totalPages = computed(() =>
  Math.ceil(sortedData.value.length / itemsPerPage.value)
);

const paginatedData = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return sortedData.value.slice(start, end);
});

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const resetPage = () => {
  currentPage.value = 1;
};

const getIdentityById = (id) => {
  return identities.value.find((itm) => itm.identityId == id) || null;
};

const sortedData = computed(() => {
  if (!sortColumn.value) return props.data;

  return [...props.data].sort((a, b) => {
    let aVal = a[sortColumn.value];

    let bVal = b[sortColumn.value];

    if (sortColumn.value === "startDate") {
      aVal = new Date(aVal);
      bVal = new Date(bVal);
    }

    if (sortColumn.value === "cost") {
      aVal =
        (parseInt(a.messagesSent) + parseInt(a.messagesOptOut)) *
        calculateTextLength(a?.initialTemplateMessage) *
        (a?.canUseMms ? a?.imgMmsRate : a?.smsRate);
      bVal =
        (parseInt(b.messagesSent) + parseInt(b.messagesOptOut)) *
        calculateTextLength(b?.initialTemplateMessage) *
        (b?.canUseMms ? b?.imgMmsRate : b?.smsRate);
    }

    if (sortColumn.value === "identityId") {
      aVal = getIdentityById(aVal)?.identityName;
      bVal = getIdentityById(bVal)?.identityName;
    }

    if (aVal < bVal) return sortDirection.value === "asc" ? -1 : 1;
    if (aVal > bVal) return sortDirection.value === "asc" ? 1 : -1;
    return 0;
  });
});

const userRole = computed(() => store.getters["user/currentUser"]?.role);
const userId = computed(() => store.getters["user/currentUser"].id);
const filteredHeadings = computed(() => {
  if (userRole.value === "ADMIN") {
    return props.headings;
  } else {
    return props.headings.filter((heading) => heading.value !== "cost");
  }
});

// const onRowClick = (job) => {
//   // if (userRole.value !== "USER") return;
//   store.commit("jobData/setJob", job);
//   if (router.currentRoute.value.name == "JobReports") {
//     router.push(`/${job.jobType.toLowerCase()}/${job.jobId}/reports`);
//   } else {
//     router.push(`/${job.jobType.toLowerCase()}/${job.jobId}`);
//   }
// };

const approveJob = async (job) => {
  await store.dispatch("jobData/approveJob", { jobId: job.jobId });
  await store.dispatch("jobData/fetchJobs", { accountId: userId.value });
};

const onMessageStatusClick = (jobId) => {
  router.push(`/chats/${jobId}`);
};

/* To consider later
const onStatusClick = (job) => {
  if (userRole.value === "ADMIN") {
    router.push(`/${job.jobType.toLowerCase()}/${job.jobId}/edit`);
  }
};
*/

const visiblePageNumbers = computed(() => {
  const totalPageCount = totalPages.value;
  const current = currentPage.value;
  const maxVisiblePages = 3; // Adjust this number to show more or fewer page numbers

  let startPage, endPage;
  if (totalPageCount <= maxVisiblePages) {
    // If total pages are less than max visible, show all pages
    startPage = 1;
    endPage = totalPageCount;
  } else {
    // Calculate start and end pages
    const middlePage = Math.floor(maxVisiblePages / 2);
    if (current <= middlePage) {
      startPage = 1;
      endPage = maxVisiblePages;
    } else if (current > totalPageCount - middlePage) {
      startPage = totalPageCount - maxVisiblePages + 1;
      endPage = totalPageCount;
    } else {
      startPage = current - middlePage;
      endPage = current + middlePage;
    }
  }

  // Generate page numbers
  let range = [];
  for (let i = startPage; i <= endPage; i++) {
    range.push(i);
  }

  // Add ellipsis if necessary
  if (startPage > 1) range.unshift("...");
  if (endPage < totalPageCount) range.push("...");

  // Always show first and last page
  if (startPage > 1) range.unshift(1);
  if (endPage < totalPageCount) range.push(totalPageCount);

  return range;
});

const goToPage = (page) => {
  currentPage.value = page;
};

const goToFirstPage = () => {
  currentPage.value = 1;
};

const goToLastPage = () => {
  currentPage.value = totalPages.value;
};

const calculateTextLength = (htmlString) => {
  // Remove HTML tags
  const textOnly = htmlString.replace(/<[^>]*>/g, "");

  // Remove extra whitespace and newlines
  const trimmedText = textOnly.replace(/\s+/g, " ").trim();

  // Return the length
  return Math.ceil(trimmedText.length / 160);
};

const onEdit = (item) => {
  store.commit("jobData/setEditJobData", item);
  router.push(`/job/edit/${item.jobId}`);
};

const onRowClick = (job) => {
  // if (userRole.value !== "USER") return;
  store.commit("jobData/setJob", job);
  // if (router.currentRoute.value.name == "JobReports") {
  router.push(`/${job.jobType.toLowerCase()}/${job.jobId}/reports`);
  // } else {
  //   router.push(`/${job.jobType.toLowerCase()}/${job.jobId}`);
  // }
};
</script>

<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>P2P SMS Jobs</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <div v-if="isLoading" class="text-center my-4">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <p class="mt-2">Loading data...</p>
        </div>
        <table
          v-else
          class="table align-items-center justify-content-center mb-0"
        >
          <thead>
            <tr>
              <th
                v-for="(heading, index) in filteredHeadings.filter(
                  (h) => h.value !== 'messagesOptOut'
                )"
                :key="index"
                @click="sort(heading.value)"
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
              >
                {{ heading.field }}
                <span
                  v-if="sortColumn === heading.value && heading.value !== null"
                >
                  {{ sortDirection === "asc" ? "▲" : "▼" }}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- <template v-for="(item, index) in paginatedData" :key="index"> -->
            <tr
              v-for="(item, index) in paginatedData"
              :key="index"
              :class="index % 2 === 0 ? 'bg-white' : 'bg-light'"
            >
              <td class="px-4">
                <date-tooltip
                  :text="`Send Date/Time:\n${getFormattedDate(item.startDate)} ${getTooltip24hTime(item.startDate)}`"
                >
                  <p class="text-xs font-weight-bold mb-0 text-center">
                    {{ getFormattedDate(item.startDate) }}
                  </p>
                </date-tooltip>
              </td>
              <td class="px-4">
                <p class="text-xs font-weight-bold mb-0 text-center">
                  {{ getIdentityById(item.identityId)?.identityName }}
                </p>
              </td>
              <td class="px-4">
                <p class="text-xs font-weight-bold mb-0 text-center">
                  {{ item.canUseMms ? "MMS" : "SMS" }}
                </p>
              </td>
              <td class="px-4">
                <p class="text-xs font-weight-bold mb-0 text-center">
                  {{ item.jobType }}
                </p>
              </td>
              <td class="px-4">
                <p class="text-xs font-weight-bold mb-0 text-center">
                  {{ item.name }}
                </p>
              </td>
              <td class="px-4 cursor-pointer">
                <p class="text-xs font-weight-bold mb-0 text-center">
                  {{ item.status.toUpperCase() }}
                  <argon-button
                    v-if="item.status === 'pending' && userRole === 'ADMIN'"
                    color="success"
                    size="sm"
                    class="action-btn rounded-circle ms-2 px-0"
                    @click.stop="approveJob(item)"
                  >
                    <i class="fas fa-check"></i>
                  </argon-button>
                </p>
              </td>
              <td
                class="px-4 text-center cursor-pointer"
                @click.stop="onMessageStatusClick(item.jobId)"
              >
                <p class="text-xs font-weight-bold mb-0">
                  {{ item.messagesSent || 0 }}
                </p>
              </td>
              <!-- <td
                class="px-4 text-center cursor-pointer"
                @click.stop="onMessageStatusClick(item.jobId)"
              >
                <p class="text-xs font-weight-bold mb-0">
                  {{ item.messagesOptOut || 0 }}
                </p>
              </td> -->
              <td
                v-if="userRole === 'ADMIN'"
                class="px-4 cursor-pointer text-center"
              >
                <p class="text-xs font-weight-bold mb-0">
                  <!-- ${{
                    (
                      parseInt(item.messagesSent) *
                        (item.initialTemplateMedia
                          ? 1
                          : calculateTextLength(item?.initialTemplateMessage)) *
                        (item?.canUseMms ? item?.imgMmsRate : item?.smsRate) +
                      parseInt(item.messagesOptOut) * item?.smsRate
                    ).toFixed(2) || "0.00"
                  }} -->
                  ${{
                    (
                      parseInt(item.messagesSent) *
                      (item.initialTemplateMedia
                        ? 1
                        : calculateTextLength(item?.initialTemplateMessage)) *
                      // (item?.canUseMms ? item?.imgMmsRate : item?.smsRate)
                      (item?.canUseMms ? item?.a2pVidMmsRate : item?.a2pSmsRate)
                    ).toFixed(2) || "0.00"
                  }}
                </p>
              </td>
              <td class="px-4 cursor-pointer text-center">
                <Tooltip text="Chat history">
                  <i
                    class="fas fa-message text-primary text-sm opacity-10 p-2"
                    @click.prevent="onMessageStatusClick(item.jobId)"
                    title="Message Status"
                  ></i>
                </Tooltip>
                <!-- <Tooltip text="View detail">
                  <i
                    class="fas fa-eye text-primary text-sm opacity-10 p-2"
                    @click.prevent="onRowClick(item)"
                    title="View Details"
                  ></i>
                </Tooltip> -->
                <Tooltip text="Edit job">
                  <i
                    v-if="item.status == 'pending'"
                    class="fas fa-edit text-primary text-sm opacity-10 p-2"
                    @click.prevent="onEdit(item)"
                    title="Edit job"
                  ></i>
                </Tooltip>
                <Tooltip text="View detail">
                  <i
                    class="fas fa-eye text-primary text-sm opacity-10 p-2"
                    @click.prevent="onRowClick(item)"
                    title="View Details"
                  ></i>
                </Tooltip>
                <Tooltip text="Delete job">
                  <i
                    v-if="userRole === 'ADMIN'"
                    class="fas fa-trash-can text-primary text-sm opacity-10 p-2"
                    @click.prevent="deleteUser(item.jobId)"
                    title="Delete User"
                  ></i>
                </Tooltip>
              </td>
            </tr>
            <!-- </template> -->
          </tbody>
        </table>
        <div
          v-if="totalPages > 1"
          class="pagination-controls d-flex justify-content-between align-items-center mt-3 px-5"
        >
          <div>
            <span class="me-2">Rows per page:</span>
            <select v-model="itemsPerPage" @change="resetPage">
              <option
                v-for="option in itemsPerPageOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
          <div class="pagination-nav">
            <button
              class="btn btn-sm btn-secondary me-1"
              @click="goToFirstPage"
              :disabled="currentPage === 1"
              title="First Page"
            >
              &lt;&lt;
            </button>
            <button
              class="btn btn-sm btn-secondary me-1"
              @click="prevPage"
              :disabled="currentPage === 1"
              title="Previous Page"
            >
              &lt;
            </button>
            <template v-for="pageNum in visiblePageNumbers" :key="pageNum">
              <button
                v-if="pageNum !== '...'"
                class="btn btn-sm me-1"
                :class="
                  pageNum === currentPage ? 'btn-primary' : 'btn-secondary'
                "
                @click="goToPage(pageNum)"
              >
                {{ pageNum }}
              </button>
              <span v-else class="mx-1">...</span>
            </template>
            <button
              class="btn btn-sm btn-secondary me-1"
              @click="nextPage"
              :disabled="currentPage === totalPages"
              title="Next Page"
            >
              &gt;
            </button>
            <button
              class="btn btn-sm btn-secondary"
              @click="goToLastPage"
              :disabled="currentPage === totalPages"
              title="Last Page"
            >
              &gt;&gt;
            </button>
          </div>
        </div>
        <DeleteConfirmationModal
          :isOpen="showDeleteModal"
          content="Do you want to delete this job?"
          itemName="User Account"
          @confirm="onDelete"
          @cancel="showDeleteModal = false"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.pagination-controls select {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}

.pagination-controls button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-nav {
  display: flex;
  align-items: center;
}

.table tbody tr:nth-child(even) {
  background-color: #f9f9f9; /* Light gray for even rows */
}

.table tbody tr:nth-child(odd) {
  background-color: #ffffff; /* White for odd rows */
}
</style>
