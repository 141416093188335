import axios from "axios";

const state = {
  donations: [],
  loading: false,
};

const mutations = {
  setDonations(state, donations) {
    state.donations = donations;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
};

const actions = {
  async fetchDonations({ commit }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/donations `
      );
      commit("setDonations", response.data.data);
    } catch (error) {
      console.log("Fetch Donations Error:", error);
    } finally {
      commit("setLoading", false);
    }
  },
};

const getters = {
  getDonations: (state) => state.donations,
  isLoading: (state) => state.loading,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
