<script setup>
import { onBeforeMount, onMounted, computed, ref, shallowRef } from "vue";
import { useStore } from "vuex";
import { getFormattedDate } from "@/utils";
import axios from "axios";
import * as XLSX from "xlsx";
// import ArgonButton from "@/components/Core/ArgonButton.vue";
// import GradientLineChart from "@/components/GradientLineChart.vue";
import {
  // getRoundedPercentage,
  // getPercentages,
  // sum,
  // removeHtmlTags,
  // extractLinks,
  convertTimestampToLocal,
} from "@/utils";
import SearchBar from "@/components/PageLayout/SearchBar.vue";
import TabGroup from "@/components/Common/TabGroup.vue";
import ClicksTable from "@/components/Dashboard/ClicksTable.vue";
import DonationsTable from "@/components/Dashboard/DonationsTable.vue";
import NonOptOutsTable from "@/components/Dashboard/NonOptOutsTable.vue";
import OptOutsTable from "@/components/Dashboard/OptOutsTable.vue";
import ArgonButton from "@/components/Core/ArgonButton.vue";

const body = document.getElementsByTagName("body")[0];
const store = useStore();
const job = computed(() => store.getters["jobData/getSelectedJob"]);
const selectedIdentity = computed(
  () => store.getters["identityData/getSelectedIdentity"]
);
const userRole = computed(() => store.getters["user/currentUser"]?.role);
// const userRole = computed(() => store.getters["user/currentUser"]?.role);

const trackingSearch = ref("");
// const outgoingMessages = computed(() => store.getters["messageData/getOutgoingMessages"]);
// const incomingMessages = computed(() => store.getters["messageData/getIncomingMessages"]);
// const failedMessages = computed(() => store.getters["messageData/getFailedMessages"]);
// const outgoingSuccessfulMessages = outgoingMessages.value?.map(item => +item.messageCount);
// const incomingSuccessfulMessages = incomingMessages.value?.map(item => +item.messageCount);
// const outgoingFailedMessages = computed(() => failedMessages.value?.map(item => +item.messageCount));
// const incomingFailedMessages = computed(() => failedMessages.value?.map(item => +item.messageCount));
const clicks = computed(() => store.getters["messageData/getClicks"]);
// const outgoingSuccessfulMessages = computed(() =>
//   outgoingMessages.value.map((m) => m.outgoingMessages)
// );
// const outgoingFailedMessages = computed(() =>
//   outgoingMessages.value.map((m) => m.failed)
// );
// const incomingSuccessfulMessages = computed(() =>
//   incomingMessages.value.map((m) => m.incomingMessages)
// );
// const incomingFailedMessages = computed(() =>
//   incomingMessages.value.map((m) => m.failed)
// );



// const chartLabels = [
//   "Jan",
//   "Feb",
//   "Mar",
//   "Apr",
//   "May",
//   "Jun",
//   "Jul",
//   "Aug",
//   "Sep",
//   "Oct",
//   "Nov",
//   "Dec",
// ];

const isHistoryLoading = ref(false)
const isDataLoading = computed(() => store.getters["trackingData/isLoading"] || store.getters["donationData/isLoading"] || isHistoryLoading.value);

const histories = ref([])
const donationLists = computed(
  () => store.getters["donationData/getDonations"]
);
const trackingLists = computed(
  () => store.getters["trackingData/getTrackings"]
);
const getDonations = async () => await store.dispatch("donationData/fetchDonations");
const getTrackingList = async () => await store.dispatch("trackingData/fetchTrackings", { jobId: job.value.jobId, });
const getHistories = async () => {
  try {
    isHistoryLoading.value = true
    histories.value = (await axios.get(`${process.env.VUE_APP_API_URL}/messages/history/${job.value.jobId}`)).data;
  }
  finally {
    isHistoryLoading.value = false;
  }
}

const onPrint = async () => {

  await getHistories()
  if (!histories.value) return;
  await getDonations()
  await getTrackingList()
  downloadCSV(
    histories.value,
    [...trackingLists.value].map((item) => ({ ...item })),
    [...donationLists.value].map((item) => ({ ...item })),
    `${job.value.name} ${convertTimestampToLocal(job.value.startDate)}`
  );
};

const downloadCSV = (downloadData, clicksData, donationData, fileName) => {
  if (!downloadData || !downloadData[0]) return;
  if (!clicksData || !clicksData[0]) return;
  if (!donationData || !donationData[0]) return;

  // Create workbook and add sheets
  const workbook = XLSX.utils.book_new();

  // Define the clicks field mapping with desired order
  const fieldMapping = {
    phonenumber: "Phone Number",
    guidlink: "Link",
    clicks: "Clicks",
    createdAt: "Date",
  };

  const fieldNonOpt = {
    from: "From Number",
    message: "Replies",
  };

  const fieldDonation = {
    recipientname: "Recipient Organization Name",
    amount: "Amount",
    firstName: "First Name",
    lastName: "Last Name",
    mobile: "Mobile",
    status: "Status",
  };

  // Extract the headers
  // const headers = Object.keys(downloadData[0]);
  // Use the ordered fields instead of all headers
  const clicksHeaders = Object.values(fieldMapping); // Get new header names in order
  const selectedFields = Object.keys(fieldMapping);

  //Non-Opt Out replies
  const nonOptHeader = Object.values(fieldNonOpt);
  const selectedNonOptFields = Object.keys(fieldNonOpt);

  //const Opt Out replies
  const optHeader = Object.values(fieldNonOpt);
  const selectedOptFields = Object.keys(fieldNonOpt);

  //Donation Header
  const donationHeaders = Object.values(fieldDonation); // Get new header names in order
  const selectedDonationFields = Object.keys(fieldDonation);

  // Create data array with headers as first row
  // const sheetData = [headers];
  const clicksSheetData = [clicksHeaders];
  const NonOptSheetData = [nonOptHeader];
  const optSheetData = [optHeader];
  const donationSheetData = [donationHeaders];

  // Add the data rows
  // for (const row of downloadData) {
  //   const values = headers.map((header) => {
  //     const val =
  //       row[header] === null || row[header] === undefined ? "" : row[header];
  //     return val;
  //   });
  //   sheetData.push(values);
  // }

  //Add the clicks data rows
  for (const row of clicksData) {
    const values = selectedFields.map((originalField) => {
      // Special handling for createdAt field
      if (originalField === "createdAt") {
        return getFormattedDate(row[originalField]) || "";
      }

      // Special handling for clicks field - convert to string to maintain consistent alignment
      if (originalField === "clicks") {
        const clickValue =
          row[originalField] === null || row[originalField] === undefined
            ? "0"
            : row[originalField];
        return String(clickValue); // Ensure it's always a string
      }

      const val =
        row[originalField] === null || row[originalField] === undefined
          ? ""
          : row[originalField];
      return val;
    });
    clicksSheetData.push(values);
  }

  // Add the Non-Opt out data rows - filtered for non-STOP messages
  for (const row of downloadData) {
    // Check if status is RECEIVED and message is not a variation of 'stop'
    if (
      row.status === "RECEIVED" &&
      row.message &&
      !row.message
        .trim()
        .toLowerCase()
        .match(/^stop[\s!.=]*$/) // matches only standalone 'stop' with optional punctuation
    ) {
      const values = selectedNonOptFields.map((originalField) => {
        const val =
          row[originalField] === null || row[originalField] === undefined
            ? ""
            : row[originalField];
        return val;
      });
      NonOptSheetData.push(values);
    }
  }

  // Add the Opt out data rows - filtered for non-STOP messages
  for (const row of downloadData) {
    if (
      row.status === "RECEIVED" &&
      row.message &&
      row.message.trim().toLowerCase() === "stop" // matches only standalone 'stop' with optional punctuation
    ) {
      const values = selectedOptFields.map((originalField) => {
        const val =
          row[originalField] === null || row[originalField] === undefined
            ? ""
            : row[originalField];
        return val;
      });
      optSheetData.push(values);
    }
  }

  //Add the donation data rows
  for (const row of donationData) {
    const values = selectedDonationFields.map((originalField) => {
      const val =
        row[originalField] === null || row[originalField] === undefined
          ? ""
          : row[originalField];
      return val;
    });
    donationSheetData.push(values);
  }

  // Create worksheet and add to workbook
  // const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
  const clicskSheet = XLSX.utils.aoa_to_sheet(clicksSheetData);
  const NonOptSheet = XLSX.utils.aoa_to_sheet(NonOptSheetData);
  const optSheet = XLSX.utils.aoa_to_sheet(optSheetData);
  const donationSheet = XLSX.utils.aoa_to_sheet(donationSheetData);

  // Add first sheet with original data
  // XLSX.utils.book_append_sheet(workbook, worksheet, "Original Data");

  // Add second sheet with same data
  XLSX.utils.book_append_sheet(workbook, clicskSheet, "Clicks");

  XLSX.utils.book_append_sheet(workbook, donationSheet, "Donations");

  XLSX.utils.book_append_sheet(workbook, NonOptSheet, "Non-Opt out Replies");

  XLSX.utils.book_append_sheet(workbook, optSheet, "Opt Outs");

  // Generate the file
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

const currentTabComponent = shallowRef()
const currentTabData = ref([])
const reportTabs = [
  {
    title: 'Clicks', onClick: async () => {
      currentTabComponent.value = ClicksTable
      await getTrackingList()
      currentTabData.value = trackingLists.value
    }
  },
  {
    title: 'Donations', onClick: async () => {
      await getDonations()
      currentTabData.value = donationLists.value
      currentTabComponent.value = DonationsTable
    }
  },
  {
    title: 'Non-Opt out Replies', onClick: async () => {
      await getHistories()
      currentTabData.value = histories.value.filter(row => row.status === "RECEIVED" &&
        row.message &&
        !row.message
          .trim()
          .toLowerCase()
          .match(/^stop[\s!.=]*$/))
      currentTabComponent.value = NonOptOutsTable
    }
  },
  {
    title: 'Opt Outs', onClick: async () => {
      await getHistories()
      currentTabData.value = histories.value.filter(row => row.status === "RECEIVED" &&
        row.message &&
        row.message.trim().toLowerCase() === "stop")
      currentTabComponent.value = OptOutsTable
    }
  },
]
onBeforeMount(() => {
  store.state.app.hideConfigButton = false;
  store.state.app.showNavbar = true;
  store.state.app.showSidenav = true;
  store.state.app.showFooter = true;
  body.classList.add("bg-gray-100");
});

onMounted(async () => {
  // const trackLinks = extractLinks(
  //   removeHtmlTags(job.value.initialTemplateMessage)
  // );
  await store.dispatch("identityData/fetchIdentity", {
    identityId: job.value.identityId,
  });
  await store.dispatch("messageData/fetchMessages", { jobId: job.value.jobId });

  await store.dispatch("messageData/fetchLinkClicks", {
    jobId: job.value.jobId,
  });

  // if (trackLinks.length > 0) {
  //   await store.dispatch("messageData/fetchLinkClicks", {
  //     jobId: job.value.jobId,
  //   });
  // }
});

const getMediaType = async (url) => {
  try {
    const response = await axios.head(url);
    const contentType = response.headers["content-type"];
    if (!contentType) {
      return "UNKNOWN";
    } else if (contentType.startsWith("image/")) {
      return "IMAGE";
    } else if (contentType.startsWith("video/")) {
      return "VIDEO";
    } else {
      return "UNKNOWN";
    }
  } catch (error) {
    console.error(`Error fetching the URL: ${error.message}`);
    return null;
  }
};

/* Consider later
const downloadJSON = (downloadData) => {
  // Convert download data to JSON string
  const jsonString = JSON.stringify(downloadData, null, 2); // Pretty print with indentation
  // Create a Blob from the JSON string
  const blob = new Blob([jsonString], { type: "application/json" });
  // Create a link element
  const link = document.createElement("a");
  // Set the URL using the Blob
  link.href = URL.createObjectURL(blob);
  // Set the file name
  link.download = "data.json";
  // Append the link to the body (required for Firefox)
  document.body.appendChild(link);
  // Programmatically click the link to trigger download
  link.click();
  // Remove the link from the body
  document.body.removeChild(link);
}
*/
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="text-white mb-4">Reports</h2>
        <div class="row">
          <div class="col-lg-4 col-8">
            <div class="d-flex align-items-center mb-2">
              <label for="account-name" class="text-white mb-0">Account Name:</label>
              <p class="text-white mb-0 ms-2">
                {{
                  selectedIdentity?.displayName ||
                  selectedIdentity?.identityName
                }}
              </p>
            </div>
            <div class="d-flex align-items-center mb-2">
              <label for="campaign-title" class="text-white mb-0">Campaign Title:</label>
              <!-- <p class="text-white mb-0 ms-2">{{ job.name }}</p> -->
              <p class="text-white mb-0 ms-2">{{ job.name }}</p>
            </div>
            <div class="d-flex align-items-center mb-2">
              <label for="send-date" class="text-white mb-0">Send Time:</label>
              <p class="text-white mb-0 ms-2">
                {{ convertTimestampToLocal(job.startDate) }}
              </p>
            </div>
            <div class="d-flex align-items-center mb-2">
              <label for="send-date" class="text-white mb-0">Send Phone Number:</label>
              <p class="text-white mb-0 ms-2">{{ job.adminPhone }}</p>
            </div>
            <div class="d-flex align-items-center mb-2">
              <label for="send-date" class="text-white mb-0">Phone List Assignments:</label>
              <p class="text-white mb-0 ms-2">
                {{
                  job?.phoneListAssignments
                    ?.map((item) => item.listName)
                    ?.join(", ") || ""
                }}
              </p>
            </div>
            <div v-if="job.initialTemplateMedia">
              <div v-if="getMediaType(job.initialTemplateMedia) === 'IMAGE'" class="media-container">
                <img :src="formData.initialTemplateMedia" alt="Image" class="media" />
              </div>
              <div v-else-if="getMediaType(job.initialTemplateMedia) === 'VIDEO'" class="media-container">
                <video controls class="media">
                  <source :src="job.initialTemplateMedia" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-8">
            <div class="card-size min-vh-40">
              <div class="">
                <div v-html="job.initialTemplateMessage" class="message-content"></div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-8">
            <div class="min-vh-40">
              <div class="card-size">
                <div class="row">
                  <div class="col-lg-12 col-12">
                    <div class="d-flex align-items-center mb-2">
                      <label for="messages-sent" class="text-black mb-0">Messages Sent:</label>
                      <p class="text-black mb-0 ms-2">
                        {{ job.messagesSent }}
                      </p>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                      <label for="replies-received" class="text-black mb-0">Non-Opt Out Replies Received:</label>
                      <p class="text-black mb-0 ms-2">
                        {{ job.messagesNonOptOut }}
                      </p>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                      <label for="replies-received" class="text-black mb-0">Opt Out Replies Received:</label>
                      <p class="text-black mb-0 ms-2">
                        {{ job.messagesOptOut }}
                      </p>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                      <label for="clicks" class="text-black mb-0">Clicks:</label>
                      <p class="text-black mb-0 ms-2">{{ clicks }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="card min-vh-50 mt-4">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6 col-12">
                <div v-html="job.initialTemplateMessage" class="message-content"></div>
              </div>
            </div>
            <div v-if="false" class="d-flex justify-content-end mb-2">
              <argon-button
                color="light"
                :disabled="loading"
                @click="onPrint"
              >
                <i class="fa fa-print text-xl text-warning"></i>
              </argon-button>
            </div>
            <div v-if="false" class="row mb-4">
              <div class="col-lg-6 col-12">
                <div class="card z-index-2">
                  <gradient-line-chart
                    v-if="outgoingSuccessfulMessages && outgoingFailedMessages"
                    id="outgoing-messages-chart"
                    title="Outgoing Messages"
                    description="<i class='fa fa-arrow-right text-success'></i>"
                    :chart="{
                      labels: chartLabels,
                      datasets: [
                        {
                          label: 'Sent',
                          data: outgoingSuccessfulMessages,
                        },
                        {
                          label: 'Failed',
                          data: outgoingFailedMessages,
                        },
                      ],
                    }"
                  />
                  <div class="row my-4 mx-2">
                    <div class="col-lg-6 col-12">
                      <div class="card z-index-3 p-2">
                        <div class="d-flex justify-content-between align-items-center mb-4">
                          <p class="mb-0">Sent</p>
                          <i class="fa fa-solid fa-circle text-success"></i>
                        </div>
                        <div>
                          <p class="text-3xl mb-0">
                            {{
                              `${
                                getRoundedPercentage(getPercentages(
                                  sum(outgoingSuccessfulMessages),
                                  sum(outgoingFailedMessages)
                                )[0])
                              }%`
                            }}
                          </p>
                          <p class="text-sm text-success mb-0">
                            {{ `${sum(outgoingSuccessfulMessages)} Messages` }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="card z-index-3 p-2">
                        <div class="d-flex justify-content-between align-items-center mb-4">
                          <p class="mb-0">Messages with Errors</p>
                          <i class="fa fa-solid fa-circle text-danger"></i>
                        </div>
                        <div>
                          <p class="text-3xl mb-0">
                            {{
                              `${
                                getRoundedPercentage(getPercentages(
                                  sum(outgoingSuccessfulMessages),
                                  sum(outgoingFailedMessages)
                                )[1])
                              }%`
                            }}
                          </p>
                          <p class="text-sm text-success mb-0">
                            {{ `${sum(outgoingFailedMessages)} Messages` }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="card z-index-2">
                  <gradient-line-chart
                    id="incoming-messages-chart"
                    title="Incoming Messages"
                    description="<i class='fa fa-arrow-left text-success'></i>"
                    :chart="{
                      labels: chartLabels,
                      datasets: [
                        {
                          label: 'Received',
                          data: incomingSuccessfulMessages,
                        },
                        {
                          label: 'Failed',
                          data: incomingFailedMessages,
                        },
                      ],
                    }"
                  />
                  <div class="row my-4 mx-2">
                    <div class="col-lg-6 col-12">
                      <div class="card z-index-3 p-2">
                        <div class="d-flex justify-content-between align-items-center mb-4">
                          <p class="mb-0">Received</p>
                          <i class="fa fa-solid fa-circle text-success"></i>
                        </div>
                        <div>
                          <p class="text-3xl mb-0">
                            {{
                              `${
                                getRoundedPercentage(getPercentages(
                                  sum(incomingSuccessfulMessages),
                                  sum(incomingFailedMessages)
                                )[0])
                              }%`
                            }}
                          </p>
                          <p class="text-sm text-success mb-0">
                            {{ `${sum(incomingSuccessfulMessages)} Messages` }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="card z-index-3 p-2">
                        <div class="d-flex justify-content-between align-items-center mb-4">
                          <p class="mb-0">Opt-out Received</p>
                          <i class="fa fa-solid fa-circle text-danger"></i>
                        </div>
                        <div>
                          <p class="text-3xl mb-0">
                            {{
                              `${
                                getRoundedPercentage(getPercentages(
                                  sum(incomingSuccessfulMessages),
                                  sum(incomingFailedMessages)
                                )[1])
                              }%`
                            }}
                          </p>
                          <p class="text-sm text-success mb-0">
                            {{ `${sum(incomingFailedMessages)} Messages` }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <div class="tracking-table">
          <div class="container-fluid py-4">
            <div class="row">
              <div class="col-12">
                <div class="card min-vh-50">
                  <div class="card-body">
                    <div class="d-flex print-icon mb-4">
                      <SearchBar v-model="trackingSearch" />

                      <div class="print-icon">
                        <ArgonButton color="light" :disabled="isDataLoading" @click="onPrint">
                          <i class="fas fa-file-export text-xl mouse-pointer"></i>
                          <!-- <i class="fa fa-print text-xl text-warning"></i> -->
                        </ArgonButton>
                      </div>
                    </div>
                    <div v-if="userRole == 'ADMIN'" class="w-100">
                      <TabGroup :tabs="reportTabs"></TabGroup>
                    </div>
                    <component v-if="userRole == 'ADMIN'" :is="currentTabComponent" :data="currentTabData"
                      :clickLoading="isDataLoading">
                    </component>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.message-content {
  p {
    margin-bottom: 0 !important;
  }
}

.card-size {
  background-color: white;
  border-radius: 8px;
  min-height: 170px;
  padding: 10px;
  margin-bottom: 4px;
}

.tracking-table {
  margin-top: 10px;
}

.pagination-controls select {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}

.pagination-controls button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-nav {
  display: flex;
  align-items: center;
}

.table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
  /* Light gray for even rows */
}

.table tbody tr:nth-child(odd) {
  background-color: #ffffff;
  /* White for odd rows */
}

.margin-left {
  margin-left: 12px;
}

.print-icon {
  justify-content: space-between;
}

.print-icon {
  display: flex;
  align-items: center;
}

.mouse-pointer {
  cursor: pointer;
}
</style>
