// store/index.js
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import app from "./modules/app";
import user from "./modules/user";
import jobData from "./modules/jobData";
import mediaData from "./modules/mediaData";
import dncData from "./modules/dncData";
import identityData from "./modules/identityData";
import agentData from "./modules/agentData";
import phoneListData from "./modules/phoneListData";
import templateData from "./modules/templateData";
import messageData from "./modules/messageData";
import chatData from "./modules/chatData";
import p2pChatData from "./modules/p2pChatData";
import donationData from "./modules/donationData";
import campaignData from "./modules/campaignData";
import trackingData from "./modules/trackingData";

export default createStore({
  modules: {
    app,
    user,
    jobData,
    mediaData,
    dncData,
    identityData,
    agentData,
    phoneListData,
    templateData,
    messageData,
    chatData,
    p2pChatData,
    donationData,
    campaignData,
    trackingData,
  },
  plugins: [createPersistedState()],
});
