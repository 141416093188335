<script setup>
import { onBeforeUnmount, onBeforeMount, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useToast } from "vue-toast-notification";
import Navbar from "@/components/PageLayout/Navbar.vue";
import ArgonInput from "@/components/Core/ArgonInput.vue";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import logo from "@/assets/logo.png";
import bgImg from "@/assets/img/bg-image.png";
import ArgonRadio from "@/components/Core/ArgonRadio.vue";

const body = document.getElementsByTagName("body")[0];
const backgroundStyle = computed(() => {
  return {
    backgroundImage: `url(${bgImg})`,
    backgroundSize: "cover",
    alignItems: "center",
  };
});
const toast = useToast();
const store = useStore();
const router = useRouter();
const forgotPassword = ref(false);
const typeAgency = ref(false);
const agencyData = ref([]);
const selectedAgency = ref(null);

let email = "";
let accountId = "";

onBeforeMount(() => {
  store.state.app.hideConfigButton = true;
  store.state.app.showNavbar = false;
  store.state.app.showSidenav = false;
  store.state.app.showFooter = false;
  body.classList.remove("bg-gray-100");
});

onBeforeUnmount(() => {
  store.state.app.hideConfigButton = false;
  store.state.app.showNavbar = true;
  store.state.app.showSidenav = true;
  store.state.app.showFooter = true;
  body.classList.add("bg-gray-100");
});

const clickContinue = async () => {
  if (!email) {
    return toast.warning("Please fill in the fields!", {
      position: "top-right",
    });
  } else {
    try {
      const result = await store.dispatch("user/confirmForForgotPassword", {
        email,
      });

      // const result = await store.dispatch("user/forgotPassword", {
      //   email,
      // });

      if (result.status) {
        typeAgency.value = true;
        agencyData.value = result.data.data;
        forgotPassword.value = false;
      } else {
        return toast.warning("Invalid Credentials!", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Fortgot passwor error:", error);
    }
  }
};

const handleAgencySelect = async (account_id) => {
  selectedAgency.value =
    selectedAgency.value === account_id ? null : account_id;
  accountId = account_id;
};

const clickReturnToLogin = () => {
  router.push("/");
};

const agentLogin = async () => {
  if (!accountId) {
    return toast.warning("Please fill in the fields!", {
      position: "top-right",
    });
  } else {
    typeAgency.value = false;
    forgotPassword.value = true;

    const result = await store.dispatch("user/forgotPassword", {
      email,
      accountId,
    });

    if (result.success)
      toast.success(result.message, { position: "top-right" });
    if (!result.success)
      toast.warning(result.message, { position: "top-right" });
  }
};
</script>

<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>

  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4
                    v-if="forgotPassword === false"
                    class="font-weight-bolder"
                  >
                    Password recovery
                  </h4>
                  <h4 v-if="forgotPassword === true" class="font-weight-bolder">
                    Check your email
                  </h4>
                  <p v-if="forgotPassword === false" class="mb-0">
                    Enter the email address associated with your account.
                  </p>
                  <div v-if="forgotPassword === true">
                    <p class="mb-0">
                      We’ve sent you an email with instructions to reset your
                      password. Check your inbox and follow the steps there.
                    </p>
                    <br />
                    If you didn’t request a password change or would like to log
                    in to a different account, select "Return to login."
                  </div>
                </div>

                <div class="card-body">
                  <div v-if="typeAgency === true">
                    <argon-radio
                      v-for="(agency, index) in agencyData"
                      :key="index"
                      :checked="selectedAgency === agency.accountId"
                      :value="agency.accountId"
                      name="agency"
                      @change="() => handleAgencySelect(agency.accountId)"
                    >
                      {{ agency.userName }}
                    </argon-radio>
                  </div>
                  <form
                    v-if="forgotPassword === false && typeAgency === false"
                    role="form"
                    @submit.prevent="clickContinue"
                  >
                    <div class="mb-3">
                      <argon-input
                        id="email"
                        type="email"
                        placeholder="Email"
                        name="email"
                        size="lg"
                        v-model="email"
                      />
                    </div>

                    <div class="text-center">
                      <argon-button
                        class="mt-2"
                        variant="gradient"
                        color="dark"
                        fullWidth
                        size="lg"
                        >Continue</argon-button
                      >
                    </div>
                  </form>
                  <div v-if="forgotPassword === true" class="text-center">
                    <argon-button
                      class="mt-2"
                      variant="gradient"
                      color="dark"
                      fullWidth
                      size="lg"
                      @click="clickReturnToLogin"
                      >Return to login
                    </argon-button>
                  </div>
                  <div v-if="typeAgency === true" class="text-center">
                    <argon-button
                      class="mt-2"
                      variant="gradient"
                      color="dark"
                      fullWidth
                      size="lg"
                      @click="agentLogin"
                      >Continue</argon-button
                    >
                  </div>
                </div>

                <div
                  v-if="forgotPassword === false"
                  class="px-1 pt-0 text-center card-footer px-lg-2"
                >
                  <p class="mx-auto mb-4 text-sm">
                    <router-link
                      to="/"
                      class="text-dark text-gradient font-weight-bold"
                      >Back</router-link
                    >
                  </p>
                </div>
              </div>
            </div>

            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden gap-3"
                :style="backgroundStyle"
              >
                <span class="mask bg-gradient-dark opacity-6"></span>
                <img
                  :src="logo"
                  class="w-50 position-relative"
                  alt="main_logo"
                />
                <p class="text-white position-relative">
                  Streamlined messaging platform for effortless, meaningful
                  connections. Simplify your communication and amplify your
                  connections with our intuitive interface and powerful
                  features.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<style scoped>
.position-relative {
  position: relative;
}
</style>
