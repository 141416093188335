<script setup>
import { ref, watch, computed } from "vue";
import { useStore } from "vuex";
import ArgonDropdown from "@/components/Core/ArgonDropdown.vue";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import axios from "axios";
// import { useToast } from "vue-toast-notification";

const store = useStore();

const props = defineProps({
  sendList: Object,
  suppressionList: Object,
  emitValidState: Function,
  addPhoneList: Function,
  identityId: Number,
});

const { emitValidState, identityId } = props;
const userId = computed(() => store.getters["user/currentUser"].id);
const isChecked = ref(false);
const rentalEnableChecked = ref(false);
const selectedRating = ref("");
const isLoading = ref(false);
const phoneNumbersList = ref([]);
const phoneNumberFlag = ref(false);
const downloadUrl = ref("");
// const toast = useToast();

const filterRating = ref([
  {
    id: 0,
    name: "All",
    value: "all",
  },
  {
    id: 5,
    name: "Rating 5",
    value: 5,
  },
  {
    id: 4,
    name: "Rating 4",
    value: 4,
  },
  {
    id: 3,
    name: "Rating 3",
    value: 3,
  },
]);

const formData = ref({
  sendList: { ...props.sendList },
  suppressionList: { ...props.suppressionList },
});

// Watch for changes in phoneListData and update formData accordingly
watch(
  () => props.sendList,
  (newValue) => {
    formData.value.sendList = {
      ...newValue,
      available: newValue.available.filter((item) => {
        if (userId.value === 46 || userId.value === "46") {
          // For userId 46, only show items matching these conditions
          return (
            (item.identityId == identityId || item.identityId == 1) &&
            // item.listType !== "Suppression"
            (item.listType !== "Suppression" ||
              !item.listType.includes("House"))
          );
          // } else if (userId.value === 2) {
          //   // For userId 2, show all items EXCEPT those matching conditions
          //   return !(
          //     // item.identityId == identityId ||
          //     (item.identityId == 1 || item.listType == "Suppression")
          //   );
        } else {
          // For other users, keep original filter

          return rentalEnableChecked.value
            ? // ? (item.identityId == identityId || item.identityId == 1) &&
              // item.identityId == 1 && item.listType !== "Suppression"?
              (item.identityId == identityId || item.identityId == 1) &&
                (item.listType !== "Suppression" ||
                  !item.listType.includes("Rental"))
            : !(
                // item.identityId == identityId ||
                (
                  item.identityId == 1 ||
                  item.listType == "Suppression" ||
                  item.listType.includes("Rental")
                )
              );
        }
      }),
      assigned: newValue.assigned,
    };
  },
  { deep: true, immediate: true }
);

watch(
  () => props.suppressionList,
  (newValue) => {
    formData.value.suppressionList = {
      ...newValue,
      available: newValue.available.filter(
        (item) =>
          item.identityId == identityId && item.listType === "Suppression"
      ),
      assigned: newValue.available.filter(
        (item) => item.identityId == identityId
      ),
    };
  },
  { deep: true, immediate: true }
);

// Watch for changes in formData
watch(
  formData,
  () => {
    emitValidState(true);
  },
  { deep: true }
);

// const onAdd = () => {
//   addPhoneList();
// };

// const onAssign = (phoneList) => {
//   formData.value = {
//     available: formData.value.available?.filter(
//       (item) => item.listId !== phoneList.listId
//     ),
//     assigned: [...formData.value.assigned, phoneList],
//   };
// store.commit("jobData/updatePhoneListStepData", formData.value);
// };

// const onRemoveDuplicate = () => {
//   //
// };

// const onKeepDuplicate = () => {
//   //
// };

// const onUnassign = (phoneList) => {
//   formData.value = {
//     available: [...formData.value.available, phoneList],
//     assigned: formData.value.assigned?.filter((item) => item.listId !== phoneList.listId),
//   };
//   store.commit("jobData/updatePhoneListStepData", formData.value);
// };

const onPhoneListSelect = (selected, e) => {
  if (e.target.checked) {
    if (
      !formData.value.sendList.assigned.find(
        (item) => item.listId === selected.listId
      )
    ) {
      formData.value.sendList.assigned = [
        ...formData.value.sendList.assigned,
        selected,
      ];
    }
  } else {
    formData.value.sendList.assigned = formData.value.sendList.assigned.filter(
      (item) => item.listId !== selected.listId
    );
  }
  store.commit("jobData/updatePhoneListStepData", formData.value.sendList);
  store.commit(
    "jobData/updateSuppressionListStepData",
    formData.value.suppressionList
  );
};

// const onSuppressionListSelect = (selected, e) => {
//   if (e.target.checked) {
//     if (!formData.value.suppressionList.assigned.find((item) => item.listId === selected.listId)) {
//       formData.value.suppressionList.assigned = [...formData.value.suppressionList.assigned, selected];
//     }
//   } else {
//     formData.value.suppressionList.assigned = formData.value.suppressionList.assigned.filter(
//       (item) => item.listId !== selected.listId
//     );
//   }
//   store.commit("jobData/updateSuppressionListStepData", formData.value.suppressionList);
// };

const checkAssigned = (data, isSuppression) => {
  const sendLists = isSuppression
    ? formData.value.suppressionList.assigned.filter(
        (item) => item.listId == data.listId
      )
    : formData.value.sendList.assigned.filter(
        (item) => item.listId == data.listId
      );
  return sendLists.length !== 0;
};

const handleClick = (event) => {
  // if (userId.value === 2) {
  //   isChecked.value = false;
  //   event.preventDefault();
  //   return;
  // }

  if (userId.value === 46 || userId.value === "46") {
    // Changed from userId to userId
    isChecked.value = true;
    event.preventDefault();
    return;
  }

  onRentalEnabledClick(event);
};

const onRentalEnabledClick = (event) => {
  rentalEnableChecked.value = event.target.checked;

  formData.value.sendList = {
    ...props.sendList,
    available: props.sendList.available.filter((item) => {
      if (userId.value === 46 || userId.value === "46") {
        return (
          (item.identityId == identityId || item.identityId == 1) &&
          // item.listType !== "Suppression"
          (item.listType !== "Suppression" || item.listType.includes("Rental"))
        );
      }

      // if (userId.value === 2) {
      //   return !(
      //     // item.identityId == identityId ||
      //     (item.identityId == 1 || item.listType == "Suppression")
      //   );
      // }

      return rentalEnableChecked.value
        ? (item.identityId == 1 && item.listType !== "Suppression") ||
            item.listType.includes("Rental")
        : !(
            // item.identityId == identityId ||
            (
              item.identityId == 1 ||
              item.listType == "Suppression" ||
              item.listType.includes("Rental")
            )
          );
    }),
    assigned: props.sendList.assigned,
  };
};

const onChangeRating = async (data) => {
  if (data.name !== "All") {
    isLoading.value = true;

    selectedRating.value = data.name;
    let ratingValue = data.value;

    // Filter formData.value.sendList to include only items with valid fileUrl
    const available = formData.value.sendList.available.filter(
      (item) => item.fileUrl
    );

    // Extract the fileUrl list from the available array
    // const fileUrlList = available.map((item) => item.fileUrl);

    const fileUrlListWithListName = available.map((item) => ({
      fileUrl: item.fileUrl,
      listName: item.listName, // Match listName with fileUrl
    }));

    // Ensure there are fileUrls in the list
    // if (fileUrlList.length === 0) {
    //   console.error("No valid fileUrls found");
    //   return;
    // }

    if (fileUrlListWithListName.length === 0) {
      console.error("No valid fileUrls found");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}/phone_numbers`,
        {
          // fileUrlList, // Send the entire list
          fileUrlListWithListName,
          ratingValue,
        }
      );

      if (response.status == 200) {
        phoneNumbersList.value = response.data.data;
        downloadUrl.value = response.data.fileUrl;
        isLoading.value = false;
        phoneNumberFlag.value = true;
      }
    } catch (error) {
      isLoading.value = false;
      console.error("Error fetching phone numbers:", error);
    }
  } else {
    phoneNumberFlag.value = false;
  }
};

// const downloadFilteredPhoneList = async () => {
//   isLoading.value = true;

//   const s3Location = downloadUrl.value.split("/").pop();

//   try {
//     const urlResponse = await axios.post(
//       `${process.env.VUE_APP_API_URL}/phone_numbers/phonenumbers-list/download`,
//       { s3Location }
//     );

//     if (!urlResponse.data.downloadUrl) {
//       throw new Error("No download URL received");
//     }

//     window.location.href = urlResponse.data.downloadUrl;

//     if (urlResponse.status === 200) {
//       toast.success("PhoneList Download successfully!", {
//         position: "top-right",
//       });
//     }
//   } catch (error) {
//     console.error("Download error:", error);

//     if (error.code === "ERR_NETWORK") {
//       toast.error("Network error occurred. Please try again.");
//     } else if (error.response) {
//       const errorMessage =
//         error.response.data?.message || "Failed to download report";
//       toast.error(errorMessage);
//     } else if (error.request) {
//       toast.error("No response received from server");
//     } else {
//       toast.error(error.message || "Failed to download report");
//     }
//   } finally {
//     isLoading.value = false;
//   }
// };
</script>

<template>
  <div class="row">
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h2 class="text-dark">Phone List</h2>

      <!-- <argon-button class="rounded-circle px-3" @click="onAdd">
        +
      </argon-button> -->
    </div>
    <div class="phonelist-filter">
      <div>
        <h6 class="mb-0 -mt-4">Rental List Enabled:</h6>
        <div class="form-check form-switch my-auto">
          <input
            class="mt-1 form-check-input"
            type="checkbox"
            id="rentalEnabled"
            v-model="isChecked"
            @click="handleClick"
          />
        </div>
      </div>
      <div v-if="userId === 4" class="d-flex align-items-center me-3">
        <argon-dropdown>
          <template v-slot:title>
            <argon-button
              color="light"
              class="dropdown-toggle"
              :disabled="loading"
            >
              {{ selectedRating || "Select Level" }}
            </argon-button>
          </template>
          <a
            v-for="(status, index) in filterRating"
            class="dropdown-item d-flex align-items-center"
            :key="index"
            @click="onChangeRating(status)"
          >
            <!-- <input type="checkbox" :id="status.id" :name="status.name" :checked="status.value" /> -->
            <label :for="status.name" class="mb-0">{{ status.name }}</label>
          </a>
        </argon-dropdown>
      </div>
    </div>

    <div v-if="isLoading" class="text-center my-4 col-lg-6 col-12">
      <div class="spinner-border text-primary loading-spinner" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <p class="mt-2">Loading data...</p>
    </div>
    <div v-else class="col-lg-6 col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="text-dark">Send Lists</h4>
        <!-- <search-bar /> -->
        <h6 v-if="formData.sendList.assigned.length > 0" class="text-dark">
          {{ formData.sendList.assigned.length }} list selected
        </h6>
      </div>
      <div class="table-responsive p-0 mt-4">
        <div v-if="phoneNumberFlag">
          <span
            v-if="downloadUrl"
            @click="downloadFilteredPhoneList()"
            style="
              display: flex;
              align-items: center;
              justify-content: left;
              gap: 10px;
              margin-top: -4px;
            "
          >
            <h6
              class="cursor-pointer hover:opacity-100"
              style="margin: 0; display: flex; align-items: center"
            >
              Please Download And Upload This Phone List Again.
            </h6>
            <i
              class="fas fa-download text-primary opacity-10 cursor-pointer hover:opacity-100"
              style="display: flex; align-items: center"
            ></i>
          </span>
          <table class="table align-items-center justify-content-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Name
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  PhoneNumber
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in phoneNumbersList" :key="index">
                <td class="px-4">{{ item.listName }}</td>
                <td class="px-4">{{ item.PhoneNumber }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <table class="table align-items-center justify-content-center mb-0">
            <thead>
              <tr>
                <th class=""></th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Name
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  State
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Leads Loaded
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in formData.sendList.available"
                :key="index"
              >
                <td class="px-4">
                  <input
                    type="checkbox"
                    id="phoneListSelect"
                    name="phoneListSelect"
                    :checked="checkAssigned(item, false)"
                    @input="(e) => onPhoneListSelect(item, e)"
                  />
                </td>
                <td class="px-4">{{ item.listName }}</td>
                <td class="px-4">{{ item.listState }}</td>
                <td class="px-4">{{ item.leadsLoaded || 0 }}</td>
                <!-- <td class="px-4">
                <div class="d-flex">
                  <argon-button
                    color="primary"
                    size="sm"
                    class="action-btn rounded-circle ms-auto px-2"
                    @click="onAssign(item)"
                  >
                    <i class="fas fa-user-plus text-xs text-white" aria-hidden="true"></i>
                  </argon-button>
                </div>
              </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-12">
      <div class="d-flex align-items-center">
        <h4 class="text-dark">Suppression Lists</h4>
      </div>
      <div class="table-responsive p-0 mt-4">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <!-- <th class=""></th> -->
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                State
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Leads Loaded
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in formData.suppressionList.available"
              :key="index"
            >
              <!-- <td class="w-5 px-4">
                <input
                  type="checkbox"
                  id="phoneListSelect"
                  name="phoneListSelect"
                  :checked="checkAssigned(item, true)"
                  @input="(e) => onSuppressionListSelect(item, e)"
                />
              </td> -->
              <td class="px-4">{{ item.listName }}</td>
              <td class="px-4">{{ item.listState }}</td>
              <td class="px-4">{{ item.leadsLoaded || 0 }}</td>
              <!-- <td class="px-4">
                <div class="d-flex">
                  <argon-button
                    v-if="item.deduplicate"
                    color="success"
                    size="sm"
                    class="action-btn rounded-circle ms-auto me-2 px-2"
                    @click="onRemoveDuplicate"
                  >
                    <i class="fas fa-users text-xs text-white" aria-hidden="true"></i>
                  </argon-button>
                  <argon-button
                    v-if="!item.deduplicate"
                    color="warning"
                    size="sm"
                    class="action-btn rounded-circle ms-auto me-2 px-2"
                    @click="onKeepDuplicate"
                  >
                    <i class="fas fa-users text-xs text-white" aria-hidden="true"></i>
                  </argon-button>
                  <argon-button
                    color="dark"
                    size="sm"
                    class="action-btn rounded-circle ms-auto px-2"
                    @click="onUnassign(item)"
                  >
                    <i
                      class="fas fa-user-minus text-xs text-white"
                      aria-hidden="true"
                    ></i>
                  </argon-button>
                </div>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style>
.action-btn {
  width: 34px;
  height: 34px;
}

.phonelist-filter {
  display: flex;
  gap: 60px;
}
</style>
