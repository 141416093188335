// store/modules/jobData.js
import axios from "axios";

const state = {
  selectedJob: null,
  jobType: "P2P",
  status: "active",
  jobs: [],
  mdrs: [],
  associatedSipPeers: [],
  siteInserviceNumbers: [],
  purchasedPhoneNumbers: [],
  availablePhoneNumbers: [],
  loading: false,
  error: null,
  content: {
    identity: "",
    jobName: "",
    jobArea: "",
    existingNumbers: [],
    allAreaCodes: [],
    quickTestNumber: "",
    selectedFile: null,
    userText: "",
    isMMSEnabled: false,
    isUseMessageBuilderEnabled: false,
    adminPhone: "",
    initialTemplateMedia: "",
    initialTemplateMessage: "",
    startDate: "",
    showAddAgentPanel: false,
    showEditJobPanel: false,
    showAddPhoneListPanel: false,
  },
  replyTemplates: {
    templateId: "",
    templateTitle: "",
    templateText: "",
    templateMedia: "",
    templates: [],
  },
  agents: {
    available: [],
    assigned: [],
  },
  deliverySchedule: {
    scheduleId: 1009028,
    scheduleName: "Custom",
    scheduleTimezone: "US/Eastern",
    isGlobal: 0,
    endDate: new Date(),
    monStart: "09:00:00",
    monEnd: "21:00:00",
    tueStart: "09:00:00",
    tueEnd: "21:00:00",
    wedStart: "09:00:00",
    wedEnd: "21:00:00",
    thuStart: "09:00:00",
    thuEnd: "21:00:00",
    friStart: "09:00:00",
    friEnd: "21:00:00",
    satStart: "09:00:00",
    satEnd: "21:00:00",
    sunStart: "09:00:00",
    sunEnd: "21:00:00",
  },
  phoneLists: {
    available: [],
    assigned: [],
  },
  suppressionLists: {
    available: [],
    assigned: [],
  },
  isValid: false,
};

const mutations = {
  initJob(state) {
    state.isValid = false;
    state.content = {
      identity: "",
      jobName: "",
      jobArea: "",
      existingNumbers: [],
      allAreaCodes: [],
      quickTestNumber: "",
      selectedFile: null,
      userText: "",
      isMMSEnabled: false,
      showEditJobPanel: false,
      isUseMessageBuilderEnabled: false,
      adminPhone: "",
      startDate: "",
      initialTemplateMedia: "",
      initialTemplateMessage: "",
      showAddAgentPanel: false,
      showAddPhoneListPanel: false,
    };
    state.replyTemplates = {
      templateId: "",
      templateTitle: "",
      templateText: "",
      templateMedia: "",
      templates: [],
    };
  },
  setJob(state, job) {
    state.selectedJob = job;
  },
  setJobs(state, jobs) {
    state.jobs = jobs;
  },
  setMDRs(state, mdrs) {
    state.mdrs = mdrs;
  },
  setJobType(state, jobType) {
    state.jobType = jobType;
  },
  setAssociatedSipPeers(state, sipPeers) {
    state.associatedSipPeers = sipPeers;
  },
  setSiteInserviceNumbers(state, phoneNumbers) {
    state.siteInserviceNumbers = phoneNumbers;
  },
  setPurchasedPhoneNumbers(state, phoneNumbers) {
    state.purchasedPhoneNumbers = phoneNumbers;
  },
  setAvailablePhoneNumbers(state, phoneNumbers) {
    state.availablePhoneNumbers = phoneNumbers;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
  updateJobType(state, payload) {
    state.jobType = payload;
  },
  updateStatus(state, payload) {
    state.status = payload;
  },
  updateContentStepData(state, payload) {
    state.content = { ...state.content, ...payload };
  },
  updateReplyTemplatesStepData(state, payload) {
    state.replyTemplates = { ...state.replyTemplates, ...payload };
  },
  toggleAddAgentPanel(state) {
    state.showAddAgentPanel = !state.showAddAgentPanel;
  },
  toggleEditJobPanel(state) {
    state.showEditJobPanel = !state.showEditJobPanel;
  },
  setEditJobData(state, payload) {
    state.content = {
      ...state.content,
      ...payload,
    };
  },
  addAgentStepData(state, payload) {
    state.agents = {
      ...state.agents,
      available: [...state.agents.available, payload],
    };
  },
  updateAgentsStepData(state, payload) {
    state.agents = { ...state.agents, ...payload };
  },
  updateDeliveryScheduleStepData(state, payload) {
    state.deliverySchedule = { ...state.deliverySchedule, ...payload };
  },
  addPhoneListStepData(state, payload) {
    state.phoneLists = {
      ...state.phoneLists,
      available: [...state.phoneLists.available, payload],
    };
  },
  setAssignedPhoneListStepData(state, payload) {
    state.phoneLists = {
      ...state.phoneLists,
      assigned: payload,
    };
  },
  addSuppressionListStepData(state, payload) {
    state.suppressionLists = {
      ...state.suppressionLists,
      available: [...state.suppressionLists.available, payload],
    };
  },
  updatePhoneListStepData(state, payload) {
    state.phoneLists = { ...state.phoneLists, ...payload };
  },
  toggleAddPhoneListPanel(state) {
    state.showAddPhoneListPanel = !state.showAddPhoneListPanel;
  },
  updateSuppressionListStepData(state, payload) {
    state.suppressionLists = { ...state.suppressionLists, ...payload };
  },
  updateValid(state, payload) {
    state.isValid = payload;
  },
  updateMDRStatus(state, { id, status }) {
    const mdr = state.mdrs.find((m) => m.id === id);
    if (mdr) {
      mdr.status = status;
    }
  },
};

const actions = {
  async fetchJobs({ commit }, { accountId }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/job?account_id=${accountId}`
      );
      commit("setJobs", response.data);
      commit("setError", null);
    } catch (error) {
      console.error("Error fetching jobs", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchMDRs({ commit }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/job/reports/mdr`
      );
      commit("setMDRs", response.data.reports);
      commit("setError", null);
    } catch (error) {
      console.error("Error fetching MDRs", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async requestMDR({ commit }, payload) {
    commit("setLoading", true);
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}/job/reports/mdr`,
        payload
      );
      commit("setError", null);
      return response.data;
    } catch (error) {
      console.error("Error requesting MDR:", error);
      commit("setError", error.message);
      throw error;
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchJob({ commit }, { jobId }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/job/${jobId}`
      );
      commit("setJob", response.data);
      commit("setError", null);
    } catch (error) {
      console.error("Error fetching job", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async filterJobs(
    { commit },
    { accountId, status, jobType, identityId, query }
  ) {
    commit("setLoading", true);
    try {
      // const response = await axios.get(`${process.env.VUE_APP_API_URL}/job?account_id=${accountId}&status=${status}&job_type=${jobType}&identity_id=${identityId}`);
      const params = {
        account_id: accountId,
        status: status,
        job_type: jobType,
        identity_id: identityId,
        query,
      };

      // Filter out undefined values
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(([key, v]) => v !== undefined && !!key)
      );
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/job`, {
        params: filteredParams,
      });
      commit("setJobs", response.data);
      commit("setError", null);
    } catch (error) {
      console.error("Error filtering jobs", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchAssociatedSipPeers({ commit }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/job/associated-sip-peers`
      );
      const associatedSipPeer = response.data?.AssociatedSipPeer;
      commit(
        "setAssociatedSipPeers",
        Array.isArray(associatedSipPeer)
          ? associatedSipPeer
          : [associatedSipPeer]
      );
      commit("setError", null);
    } catch (error) {
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchSiteInserviceNumbers({ commit }, { siteId, sippeerId }) {
    commit("setLoading", true);
    try {
      const params = {
        site_id: siteId,
        sip_peer_id: sippeerId,
      };
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/job/site-inservice-numbers`,
        { params }
      );
      commit("setSiteInserviceNumbers", response.data);
      commit("setError", null);
    } catch (error) {
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchPurchasedPhoneNumbers({ commit }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/job/purchased-phone-numbers`
      );

      commit("setPurchasedPhoneNumbers", response.data?.phoneNumbers);
      commit("setError", null);
    } catch (error) {
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchAvailablePhoneNumbers({ commit }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/job/available-phone-numbers`
      );
      commit("setAvailablePhoneNumbers", response.data);
      commit("setError", null);
    } catch (error) {
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async addSchedule({ commit }, payload) {
    commit("setLoading", true);
    try {
      await axios.post(`${process.env.VUE_APP_API_URL}/schedule`, payload);
      commit("setError", null);
    } catch (error) {
      console.error("Error adding schedule", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async addJob({ commit }, payload) {
    commit("setLoading", true);
    try {
      await axios.post(`${process.env.VUE_APP_API_URL}/job`, {
        ...payload,
      });
      if (payload.jobType === "Soapbox P2P") {
        await axios.post(`${process.env.VUE_APP_API_URL}/job/quicktest`, {
          to: process.env.VUE_APP_ADMIN_PHONE,
          text: "Soapbox P2P Job Created",
          tag: "Soapbox P2P Job Creation",
        });
      }
      commit("setError", null);
    } catch (error) {
      console.error("Error adding job", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async editJob({ commit }, payload) {
    commit("setLoading", true);
    try {
      await axios.put(`${process.env.VUE_APP_API_URL}/job/${payload.jobId}`, {
        ...payload.data,
      });
      // if (payload.jobType === 'Soapbox P2P') {
      //   await axios.post(`${process.env.VUE_APP_API_URL}/job/quicktest`, {
      //     to: process.env.VUE_APP_ADMIN_PHONE,
      //     text: 'Soapbox P2P Job Created',
      //     tag: 'Soapbox P2P Job Creation',
      //   });
      // }
      commit("setError", null);
    } catch (error) {
      console.error("Error adding job", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async approveJob({ commit }, payload) {
    commit("setLoading", true);
    try {
      await axios.post(`${process.env.VUE_APP_API_URL}/job/approve`, {
        jobId: payload.jobId,
      });
      commit("setError", null);
    } catch (error) {
      console.error("Error approving job", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async deleteJob({ commit }, payload) {
    commit("setLoading", true);
    try {
      console.log(payload);

      await axios.delete(`${process.env.VUE_APP_API_URL}/job/`, {
        data: { ids: payload },
      });
      commit("setError", null);
    } catch (error) {
      console.error("Error adding DNC", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async testQuick({ commit }, payload) {
    commit("setLoading", true);
    try {
      await axios.post(`${process.env.VUE_APP_API_URL}/job/quicktest`, payload);
    } catch (error) {
      console.error("Error testing quick", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
};

const getters = {
  getSelectedJob: (state) => state.selectedJob,
  getStatus: (state) => state.status,
  getPhonList: (state) =>
    state.jobData.phoneLists ? state.jobData.phoneLists : [],
  getJobType: (state) => state.jobType,
  getJobs: (state) => state.jobs,
  getMDRs: (state) => state.mdrs,
  getAssociatedSipPeers: (state) => state.associatedSipPeers,
  getSiteInserviceNumbers: (state) => state.siteInserviceNumbers,
  getPurchasedPhoneNumbers: (state) => state.purchasedPhoneNumbers,
  getEditContent: (state) => state.content,
  isMMSEnabled: (state) => state.content.isMMSEnabled,
  isLoading: (state) => state.loading,
  getError: (state) => state.error,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
