<script setup>
import { onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";

const router = useRouter();
const guid = computed(() => router.currentRoute.value.params.id);

// Create a loading HTML element only for campaign pages
const createLoadingPage = () => {
  // Check if current route is a campaign route
  if (router.currentRoute.value.name === "campaign") {
    document.documentElement.style.background = "white";
    document.body.style.background = "white";
    document.body.style.visibility = "hidden";
  }
};

onMounted(async () => {
  createLoadingPage();

  try {
    const id = guid.value;
    const response = await axios.get(`${process.env.VUE_APP_API_URL}/${id}`, {
      timeout: 4000,
    });

    if (response.status === 200 && response.data.data.campaignid) {
      window.location.replace(response.data.data.campaignid);
    } else {
      router.back();
    }
  } catch (error) {
    router.back();
  }
});
</script>

<template>
  <div class="campaign-redirect"></div>
</template>

<style scoped>
.campaign-redirect {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 9999;
}
</style>
