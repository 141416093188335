//store/modules/p2pChatData.js
import axios from "axios";

const state = {
  recipients: [],
  selectedRecipient: null,
  chats: [],
  loading: false,
  error: null,
  identityId: null,
};

const mutations = {
  setRecipients(state, recipients) {
    state.recipients = recipients;
  },
  setSelectedRecipient(state, recipient) {
    state.selectedRecipient = recipient;
  },
  setChats(state, chats) {
    state.chats = chats;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
  setIdentity(state, identityId) {
    state.identityId = identityId;
  },
};

const actions = {
  async fetchRecipients({ commit }, { listId }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/p2pchats/${listId}/recipients`
      );

      commit("setRecipients", response.data.data);
      commit("setIdentity", response.data.identityId);
      commit("setError", null);
    } catch (error) {
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchChatHistory({ commit }, { listId, recipient }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/p2pchats/${listId}?recipient=${encodeURIComponent(recipient)}`
      );
      commit("setChats", response.data);
      commit("setError", null);
    } catch (error) {
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },

  async testQuick({ commit }, payload) {
    commit("setLoading", true);
    try {
      await axios.post(
        `${process.env.VUE_APP_API_URL}/p2pchats/quicktest`,
        payload
      );
    } catch (error) {
      console.error("Error testing quick", error);
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
};

const getters = {
  getRecipients: (state) => state.recipients,
  getSelectedRecipient: (state) => state.selectedRecipient,
  getChats: (state) => state.chats,
  isLoading: (state) => state.loading,
  getError: (state) => state.error,
  getIdentityId: (state) => state.identityId,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
