<template>
    <div class="tab-container">
        <button v-for="(tab, index) in tabs" :key="index" :class="[
            'tab-button px-4 py-2',
            activeTab === index ? 'active' : '',
        ]" @click="tabClicked(tab, index)">
            {{ tab.title }}
        </button>
    </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';

const props = defineProps({
    tabs: {
        type: Array,
        required: true
    }
})
const activeTab = ref(0)

const tabClicked = (tab, index) => {
    activeTab.value = index
    if (tab.onClick) tab.onClick()
}

onMounted(() => {
    if (props.tabs[0].onClick) props.tabs[0].onClick()
})
</script>
<style scoped>
.tab-container {
    display: inline-flex;
    background: #f8f9fa;
    /* Light gray background */
    border-radius: 1rem;
    padding: 3px;
    /* Added padding for inner spacing */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.tab-button {
    padding: 8px 24px;
    border: none;
    color: #495057;
    /* Professional dark gray for text */
    font-size: 14px;
    font-weight: 500;
    /* Medium weight for better readability */
    cursor: pointer;
    border-radius: 1rem;
    position: relative;
    transition: all 0.2s ease;
    background: transparent;
}

.tab-button:hover:not(.active) {
    background: rgba(0, 0, 0, 0.04);
    /* Subtle hover effect */
}

.tab-button.active {
    background-color: #e9ecef;
    /* More professional gray for active state */
    color: #212529;
    /* Darker text for active state */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.tab-content {
    margin-top: 20px;
}
</style>