import axios from "axios";

const state = {
  link: null,
};

const mutations = {
  setLink(state, link) {
    state.link = link;
  },
};

const actions = {
  async fetchLink({ commit }, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}/job/campaign/link`,
        payload
      );

      commit("setLink", response.data);
    } catch (error) {
      console.log("Fetch Link Error:", error);
    }
  },
};

const getters = {
  getLink: (state) => state.link,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
